<template>
    <v-parallax src="..\assets\1728400384490.jpg">
  <div>
    <v-container fluid style="height: 100vh; overflow-y: auto;">
      

      <!-- 音乐列表标题区域 -->
      <v-row class="mb-4">
        <v-col cols="12" sm="6">
          <v-row no-gutters align="center">
            <v-col cols="auto" class="pr-0">
              <v-img class="ml-2 mr-5" :src="img" width="100" height="100" contain></v-img>
            </v-col>
            <v-col class="text-left">
              <h2>{{ musicListTitle }}</h2>
            </v-col>
           
          </v-row>
        </v-col>
        
      </v-row>
      <v-divider class="mt-10 mb-5"></v-divider>

      <div>
        <v-empty-state v-if="musicList.length === 0 && songloging===false && isNetworkError ===false"
        icon="mdi-magnify"
        text="这里什么也没有~"
        title="空空如也"
      ></v-empty-state>

      <v-empty-state v-if="musicList.length === 0  && songloging===true && isNetworkError ===false"
        icon="mdi-spin mdi-loading"
        title="加载中"
        text="正在奋力加载，精彩马上呈现~"
      ></v-empty-state>

      <v-empty-state v-if="musicList.length === 0  && songloging===false && isNetworkError ===true"
        icon="mdi-cancel"
        title="加载失败"
        text="网络错误，请检查您的网络连接"
      ></v-empty-state>
      </div>
      
      <!-- 音乐列表 -->
      <v-row  v-if="musicList.length > 0"  align="center" justify="center" dense>
    <v-col cols="12" md="12" v-for="(music, index) in musicList" :key="index">
        
      <v-card
     
        class="mx-auto"
        :subtitle="translateMusicGenreToChinese(music.tags)"
        :title="music.title"
        color="#000000"
        @click="spkiPlay(music,musicList)"
      >
        <template v-slot:prepend>
            <v-col cols="auto" class="pa-0 mr-2">
              <v-img :src="music.native_image_url" width="88" height="66" contain></v-img>
            </v-col>
        </template>
        <template v-slot:append>
          <v-card-actions>
          <v-btn icon><v-icon>mdi-play</v-icon></v-btn>
          </v-card-actions>
        </template>
      </v-card>

    </v-col>
  </v-row>

    </v-container>
  </div>
</v-parallax>
  
  </template>
  
  <script>
  export default {
    name: 'MusicList',
    data() {
      return {
        musicListTitle: '加载中',
        songloging:false,
        isNetworkError:false,
        //关键词
        keyword: '',
        img:'https://cdn.vuetifyjs.com/images/cards/house.jpg',
        musicList: [
         
        ],
        genreMap: {
          "Male Vocal":"男声",
      "Female Vocal":"女声",
  "Hip Hop": "嘻哈",
  "R&B/Soul": "R&B",
  "Traditional/Chinese Style": "古风音乐",
  "Post-Rock": "后摇",
  "Classical Music": "古典音乐",
  "Jazz": "爵士乐",
  "Rock": "摇滚乐",
  "Pop": "流行音乐",
  "Hip Hop/Rap": "说唱音乐",
  "Electronic Dance Music (EDM)": "电子舞曲",
  "Country": "乡村音乐",
  "Folk": "民谣音乐",
  "Blues": "蓝调音乐",
  "Soul": "灵魂乐",
  "Heavy Metal": "重金属音乐",
  "Punk Rock": "朋克摇滚",
  "Funk": "放克音乐",
  "Reggae": "雷鬼音乐",
  "World Music": "世界音乐",
  "Alternative Rock": "另类摇滚",
  "Indie": "独立音乐",
  "Hard Rock": "硬摇滚",
  "New Age": "新世纪音乐",
  "Jazz Rock": "爵士摇滚",
  "Progressive Rock": "进步摇滚",
  "Experimental": "实验音乐",
  "Electronic": "电子音乐",
  "Industrial": "工业音乐",
  "Soft Rock": "抒情摇滚",
  "Pop Punk": "流行朋克",
  "Chicago Blues": "芝加哥蓝调",
  "Delta Blues": "德尔塔蓝调",
  "Nu Jazz/Electro Jazz": "电子爵士",
  "Ambient": "环境音乐",
  "Ethnic/World": "民族音乐",
  "Latin": "拉丁音乐",
  "K-POP": "K-POP",
  "J-POP": "J-POP",
  "Opera": "歌剧",
  "Chamber Music": "室内乐",
  "Drum and Bass": "D&B（鼓打贝斯）",
  "Cuban Music": "古巴音乐",
  "Bossa Nova": "博萨诺瓦",
  "Early Music": "古代音乐",
  "Electro Punk": "庞克电子",
  "Crunk": "嘻游",
  "Grammy Style (not a genre but associated with varying styles recognized at the Grammy Awards)": "格莱美风格",
  "Black Metal": "黑金属",
  "Death Metal": "死亡金属",
  "Folk Metal": "民谣金属",
  "Gothic Rock": "哥特摇滚",
  "Experimental Rock": "实验摇滚",
  "Digital Hardcore": "数码硬核",
  "Electroacoustic Folk": "电子民谣",
  "Post-Punk": "后朋克",
  "New Wave": "新浪潮",
  "Emo (Emotional Hardcore)": "情感硬核",
  "Ska": "斯卡音乐"
},
      };
    },
    created() {
      this.songloging=true;
      this.isNetworkError=false;
      window.scrollTo(0, 0);
      if(this.$route.query.type){
        this.musicListTitle = this.base64ToString(this.$route.query.type)
      }

      if(this.$route.query.keyword){
        this.keyword = this.base64ToString(this.$route.query.keyword)
      }

      if(this.$route.query.img){
        this.img = this.base64ToString(this.$route.query.img)
        this.getSong();
      }
    },
    methods: {
      translateMusicGenreToChinese(str) {
    const reverseGenreMap = this.genreMap;
    return str.replace(/(Hip Hop|R&B\/Soul|Traditional\/Chinese Style|Post-Rock|Male Vocal|Female Vocal|Classical Music|Jazz|Rock|Pop|Hip Hop\/Rap|Electronic Dance Music \(EDM\)|Country|Folk|Blues|Soul|Heavy Metal|Punk Rock|Funk|Reggae|World Music|Alternative Rock|Indie|Hard Rock|New Age|Jazz Rock|Progressive Rock|Experimental|Electronic|Industrial|Soft Rock|Pop Punk|Chicago Blues|Delta Blues|Nu Jazz\/Electro Jazz|Ambient|Ethnic\/World|Latin|K-POP|J-POP|Opera|Chamber Music|Drum and Bass|Cuban Music|Bossa Nova|Early Music|Electro Punk|Crunk|Grammy Style \(not a genre but associated with varying styles recognized at the Grammy Awards\)|Black Metal|Death Metal|Folk Metal|Gothic Rock|Experimental Rock|Digital Hardcore|Electroacoustic Folk|Post-Punk|New Wave|Emo \(Emotional Hardcore\)|Ska)/g, match => reverseGenreMap[match]);
  },
      toBase64(str) {
    // 使用 TextEncoder 将字符串转换为 ArrayBuffer
    const buffer = new TextEncoder().encode(str);
    // 使用 btoa 将 ArrayBuffer 转换为 Base64 字符串
    return btoa(String.fromCharCode(...new Uint8Array(buffer)));
},
          //跳转播放页面
    spkiPlay(card,cards) {
      let cardsId = card.native_id;
      for(let i = 0; i < cards.length; i++){
        cardsId = cardsId + ',' + cards[i].native_id;
      }
      let cardId = this.toBase64(card.native_id);
      cardsId = this.toBase64(cardsId);
      this.$router.push({
      path: '/musicPlayer',
      query: { id:  cardId,ids : cardsId }
    });
    },
      getSong(){
        this.$axios.get('/music/search?keyword='+this.keyword).then(response => {
          this.musicList = response.data.data
          if(this.musicList.length===0){
            this.songloging = false;
          }
           }).catch(error => {
        if(error.message ==="Network Error"){
          this.isNetworkError = true;
          this.songloging = false;
        }
      });
      },
      base64ToString(base64) {
      // 使用 atob 将 Base64 字符串转换为 ArrayBuffer
      const binaryString = atob(base64);
      // 使用 TextDecoder 将 ArrayBuffer 转换为原始字符串
      return new TextDecoder("utf-8").decode(new Uint8Array([...binaryString].map(c => c.charCodeAt(0))));
},
      goBack() {
        // 返回上一级逻辑
      },
      likeMusic(index) {
        this.musicList[index].likes++;
      },
      shareMusic(index) {
      },
    },
  };
  </script>
  
  <style scoped>
  /* 样式自定义 */
  .v-card {
    margin-bottom: 8px; /* 上下间隔小于10px */
  }
  
  .v-card-title,
  .v-card-subtitle {
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .v-card-title {
    font-size: 1rem;
    line-height: 1.2rem;
  }
  
  .v-card-subtitle {
    font-size: 0.9rem;
    line-height: 1rem;
  }
  
  .v-card-actions {
    padding: 0;
  }
  
  .v-row {
    height: 110px;
  }
  
  .v-col {
    display: flex;
    align-items: center;
  }
  
  .v-col:nth-child(1) {
    justify-content: flex-start;
  }
  
  .v-col:nth-child(2) {
    justify-content: flex-start;
  }
  
  .v-col:nth-child(3) {
    justify-content: flex-end;
  }
  </style>