import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import Create from '../views/Create.vue'
import MyWork from '../views/MyWork.vue'
import Member from '../views/Member.vue'
import PersonalCenter from '../views/PersonalCenter.vue'
import Payment from '../views/Payment.vue'
import MusicPlayer from '../views/MusicPlayer.vue'
import MusicList from '../views/MusicList.vue'
import BriefIntroduction from '../views/BriefIntroduction.vue'
import ContactUs from '../views/ContactUs.vue'
import FrequentlyAskedQuestions from '../views/FrequentlyAskedQuestions.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/create',
    name: 'create',
    component: Create
  },
  {
    path: '/mywork',
    name: 'mywork',
    component: MyWork
  },
  {
    path: '/member',
    name: 'member',
    component: Member
  },
  {
    path: '/profile',
    name: 'profile',
    component: PersonalCenter
  },
  {
    path: '/payment',
    name: 'payment',
    component: Payment
  },
  {
    path: '/musicPlayer',
    name: 'musicPlayer',
    component: MusicPlayer
  },
  {
    path: '/musicList',
    name: 'musicList',
    component: MusicList
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: ContactUs
  },
  {
    path: '/faq',
    name: 'faq',
    component: FrequentlyAskedQuestions
  }
  ,
  {
    path: '/about',
    name: 'about',
    component: BriefIntroduction
  }
  
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
