<template>
  <div class="ma-5">
    

    <v-dialog
      v-model="dialog"
      width="auto"
    >
      <v-card
        text="删除无法恢复，要继续吗？"
        title="温馨提示："
      >
        <template v-slot:actions>
          <v-btn
            text="取消"
            class="text-green" min-width="92" variant="text" 
            @click="dialog = false"
          ></v-btn>
          <v-btn
            text="确定"
            class="text-red" min-width="92" variant="text" 
            @click="delSong"
          ></v-btn>
        </template>
      </v-card>
    </v-dialog>

    
    <div class="container">
      <v-chip prepend-icon="mdi-briefcase" variant="text">
        <span class="music-type" @click="startCountdown">作品列表</span>
      </v-chip>
    </div>
    <v-divider v-if="musicCards.length === 0" class="border-opacity-100"></v-divider>

    <v-empty-state v-if="musicCards.length === 0 && songloging === false && isNetworkError === false" icon="mdi-magnify"
      text="快去创作您的第一首歌曲吧！" title="空空如也"></v-empty-state>

    <v-empty-state v-if="musicCards.length === 0 && songloging === true && isNetworkError === false"
      icon="mdi-spin mdi-loading" title="加载中" text="正在奋力加载，精彩马上呈现~"></v-empty-state>

    <v-empty-state v-if="musicCards.length === 0 && songloging === false && isNetworkError === true" icon="mdi-cancel"
      title="加载失败" text="网络错误，请检查您的网络连接"></v-empty-state>
    <v-row v-if="musicCards.length > 0">
      <v-col cols="12" sm="3" v-for="(card, index) in musicCards" :key="index">
        <v-card color="#616161" :image="card.image" @click="spkiPlay(card, musicCards)">
          <div>
            <div>
              <div>
                <v-card-title :class="card.status===1?'cardTitle mb-3 text-h5 d-flex justify-space-between align-center':'text-h5 d-flex justify-space-between align-center'">
    <span >
      {{ truncateSubtitle2(card.title) }}
    </span>
    <v-switch
    v-if="card.status===1 || card.status===0"
    :model-value="card.model"
    :label="`${card.model ? '公开' : '私有'}`"
    color="green"
    hide-details
    @click.stop="open(index)"
  ></v-switch>

  <v-switch
  v-else
  v-model="card.model"
    label="失败"
    color="primary"
    false-value="yes"
    true-value="no"
    hide-details
    @click.stop=""
    disabled
        ></v-switch>
  </v-card-title>
                <v-card-subtitle style="opacity: 1;"><span :class="card.status===1?'cardTitle':''">{{ truncateSubtitle(translateMusicGenreToChinese(card.subtitle)) }}</span></v-card-subtitle>
                <v-card-actions>
                  <v-btn @click.stop="playAudio(index, card, card.audio)" :class="card.status===1?'ms-2 cardPlay':'ms-2'" :icon=card.icon
                    variant="text"></v-btn>
                </v-card-actions>
              </div>
              <div>
              <!-- 功能按钮区 -->
              <v-card-actions class="cardActions">
                
                <v-btn @click.stop="likeMusic(index)" :class="{ 'red': card.liked, 'grey': !card.liked }">
                  <v-icon>mdi-thumb-up</v-icon>
                  <!-- {{ formatNumber(card.likes) }} -->
                </v-btn>
                <v-btn v-if="(windowWidth < 1580 && windowWidth < 600) || (windowWidth > 1580)"><v-icon
                    class="mb-1">mdi-headphones</v-icon>{{ formatNumber(card.play) }}</v-btn>
                <v-btn v-if="(windowWidth < 1110 && windowWidth < 600) || (windowWidth > 1110)"
                  @click.stop="copyToClipboard(card.status, card)"><v-icon class="mb-1">mdi-share</v-icon>
                  <!-- {{ formatNumber(card.forwardCount) }} -->
                </v-btn>

                <!-- 卡片功能菜单 -->
                <v-menu @click.stop="" :location="location">
                  <template v-slot:activator="{ props }">
                    <v-btn>
                      <v-icon class="mb-1" v-bind="props">mdi-menu</v-icon></v-btn>
                  </template>

                  <v-list>
                    <v-list-item v-for="(item, index) in cardMenu" :key="index">
                      <v-list-item-title>
                        <v-btn @click.stop="task(item.id, card)">
                          <v-icon>{{ item.icon }}</v-icon>
                          {{ item.title }}
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>


              </v-card-actions>
            </div>
            </div>

          </div>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-pagination v-if="totalPages > 1" v-model="page" :length="totalPages" next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left" @click="onPageChange"></v-pagination>
    </div>


    <!-- 消息提示 -->
    <v-snackbar :timeout="2000" v-model="snackbar" color="white" vertical>
      <div class="text-subtitle-1 pb-2">提示</div>
      <p>{{ text }}</p>

      <template v-slot:actions>
        <v-btn color="pink" variant="text" @click="snackbar = false">
          关闭
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>

export default {
  name: 'MyWork',

  data: () => ({
    windowWidth: window.innerWidth,
    snackbar: false,
    songloging: false,
    isNetworkError: false,
    input: '',
    text: '',
    page: 1,
    dialog: false,
    delSongId:0,
    remainingTime: 0, // 倒计时
    timerConditions: true,
    countdownInterval: null,
    totalPages: 1,
    //分享连接url
    shareUrl: 'https://sunosong.cn',
    cardMenu: [
      { id: 1, title: '下载音频', icon: 'mdi-download' },
      { id: 2, title: '生成类似', icon: 'mdi-refresh' },
      // { id: 3, title: '延长音频', icon: 'mdi-playlist-plus' },
      // { id: 4, title: '关闭背景', icon: 'mdi-image-off' },
      { id: 5, title: '音乐分享', icon: 'mdi-share' },
      { id: 6, title: '删除音乐', icon: 'mdi-delete' },

    ],
    locations: [
      'top',
      'bottom',
      'start',
      'end',
      'center',
    ],
    location: 'end',
    musicCards: [

    ],

  }),
  created() {
    this.songloging = true;
    this.isNetworkError = false;

    //验证是否登录
    this.$axios.get('/music/test').then(response => {
      if (response.data.code === 2000) {
        this.startCountdown();
        this.$axios.get('/music/song/listNumber').then(response => {
          if (response.data.data % 20 == 0) {
            this.totalPages = response.data.data / 20;
          } else {
            this.totalPages = (response.data.data / 20) + 1;
          }
        }).catch(error => { });
        this.getSongList();
      }

    }).catch(error => {
      if (error.message === "Network Error") {
        this.text = "网络连接失败，请检查网络后重试！"
        this.isNetworkError = true;
        this.snackbar = false;
      }
      this.songloging = false;
    });



  },
  methods: {
    delSong(){
      this.$axios.post('/music/deleteSong',{nativeId: this.delSongId}).then(response => {
        if(response.data.code===2000){
          this.dialog = false;
          this.text = '删除成功！';
          this.snackbar = true;
          //刷新页面
          location.reload();
        }
      })
    },
    generateSimilar(name,style) {
      this.$router.push({
      path: '/create',
      query: { name: this.toBase64(name),style:this.toBase64(style) }
    });
    },
    open(index){
      const nativeId = this.musicCards[index].id;
      
      this.$axios.post('/music/openSong',{
        openId:this.musicCards[index].model?0:1,
        nativeId:nativeId        
      }).then(response => {
        this.snackbar=true;
        this.text="状态修改成功！";
        this.musicCards[index].model = !this.musicCards[index].model;
      }).catch(error=>{
      })
    },
    truncateSubtitle(subtitle) {
      if (subtitle.length > 20) {
        return subtitle.slice(0, 20) + '...';
      }
      return subtitle;
    },
    truncateSubtitle2(subtitle) {
      if (subtitle.length > 5) {
        return subtitle.slice(0, 5) + '...';
      }
      return subtitle;
    },
    translateMusicGenreToChinese(str) {
      const parts = str.split(',');
      let newStr = '';
      for(let i = 0; i < parts.length; i++){
        if(this.$genreMapReversed[parts[i]]){
          newStr+=this.$genreMapReversed[parts[i]]+','
        }else{
          newStr+=parts[i]+','
        }
        
      }
      if (newStr.endsWith(',')) { 
        return newStr.slice(0, -1); // 返回去掉最后一个字符的字符串
      }
      return newStr;
    },
    async startCountdown() {
      this.countdownInterval = setInterval(() => {
        //是否在当前页面
        if (this.$route.name === 'mywork' && this.timerConditions) {
          this.remainingTime++;

          //每十秒执行一次
          if (this.remainingTime % 10 === 0) {
            let task = '';
            // 判断是否有未完成卡片
            for (let i = 0; i < this.musicCards.length; i++) {
              //如果客户端有未完成的任务
              if (this.musicCards[i].status === 0) {
                task = this.musicCards[i];
                this.musicCards[i].subtitle = '生成中(' + this.progress(this.musicCards[i].createdAt)+')'
              }
            }

            if (task === '') {
              this.timerConditions = false;
            } else {
              //获取服务器未完成任务数
              this.$axios.get('/music/song/generateNumber').then(response => {
                //如果服务器没有未完成任务
                if (response.data.data === 0) {
                  //重新获取音乐列表
                  this.getSongList();
                  //回收计时器
                  this.snackbar = true;
                  this.text = '恭喜！音乐已创作完成！快去欣赏一下吧~';
                }
              }).catch(error => { });

            }

          }
        } else {
          clearInterval(this.countdownInterval);
        }
      }, 1000);
    },
    onPageChange() {
      this.getSongList();
    },
    //格式化数字显示
    formatNumber(number) {
      if (number >= 10000) {
        return (number / 10000).toFixed(1) + 'w';
      }
      else if (number >= 1000) {
        return (number / 1000).toFixed(1) + 'k';
      } else {
        return number.toString();
      }
    },
    likeMusic(index) {
      if(this.musicCards[index].status===0){
        this.snackbar = true;
        this.text = '音乐生成中，请稍后~';
        return;
      }

      if(this.musicCards[index].status===-1){
        this.snackbar = true;
        this.text = '无法点赞失败的作品';
        return;
      }
      //点赞功能
      if (!this.musicCards[index].liked) {
        this.musicCards[index].likes++;
        this.musicCards[index].liked = !this.musicCards[index].liked;
      } else {
        this.musicCards[index].likes--;
        this.musicCards[index].liked = !this.musicCards[index].liked;
      }

    },
    progress(time) {
      const t = (Date.now() - time) / 2000
      if(t < 0){
        return 0+"%";
      }
      if(t<=98){
        return t.toFixed(2)+"%";
      }
      if (t > 98 && t < 132) {
        return 99.00+"%";
      }
      return 99.00+"% 目前使用人数多，努力生成中~";
    },
    //跳转播放页面
    spkiPlay(card, cards) {
      if(card.status===0){
        this.snackbar = true;
        this.text = '音乐生成中，请稍后~';
        return;
      }

      if(card.status===-1){
        this.snackbar = true;
        this.text = '抱歉，作品生成失败，失败原因：服务器忙碌或者作品中包含敏感词汇，您可以删除该作品，并尝试重新生成作品';
        return;
      }
      let cardsId = card.id;
      for (let i = 0; i < cards.length; i++) {
        cardsId = cardsId + ',' + cards[i].id;
      }
      let cardId = this.toBase64(card.id);
      cardsId = this.toBase64(cardsId);
      this.$router.push({
        path: '/musicPlayer',
        query: { id: cardId, ids: cardsId }
      });
    },
    toBase64(str) {
      // 使用 TextEncoder 将字符串转换为 ArrayBuffer
      const buffer = new TextEncoder().encode(str);
      // 使用 btoa 将 ArrayBuffer 转换为 Base64 字符串
      return btoa(String.fromCharCode(...new Uint8Array(buffer)));
    },
    updateWindowDimensions() {
      this.windowWidth = window.innerWidth;
    },
    onClick() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.loaded = true;
      }, 2000);
    },
    //获取音乐列表
    async getSongList() {
      this.$axios.get('/music/song/list?page=' + this.page).then(response => {
        console.log(response)
        const songs = [];
        for (let i = 0; i < response.data.data.length; i++) {
          const data = response.data.data[i];
          
          songs.push({
            id: data.native_id === null ? 0 : data.native_id,
            typeId: 3,
            status: data.complete,
            title: data.complete === 1 ? data.title : data.complete === -1 ? '生成错误' : '生成中',
            subtitle: data.complete === 1 ? data.tags : data.complete === -1 ? '积分已退还' : '生成中('+this.progress(new Date(data.created_at).getTime())+')',
            image: data.complete === -1 ? '' : data.native_image_url,
            audio: data.native_audio_url,
            color: '#616161',
            icon: data.complete === 0 ? 'mdi-spin mdi-loading' : data.complete === -1 ? 'mdi-alert-circle' : 'mdi-play',
            likes: data.likeCount,
            play: data.play_count,
            createdAt: new Date(data.created_at).getTime(),
            forwardCount: data.forward_count,
            liked: false,
            model: data.open===1?true:false,
          });
        }
        this.musicCards = songs;

        if (this.$globalObject.typeId === 3 && !this.$globalAudio.paused) {
          if (this.musicCards[this.$globalObject.index].status === 1) {
            this.musicCards[this.$globalObject.index].icon = 'mdi-pause'
          }
        }


        for (let i = 0; i < this.musicCards.length; i++) {
          if (this.musicCards[i].status === 0) {
            this.musicCards[i].icon = 'mdi-spin mdi-loading';
          }
        }
        this.songloging = false;
      }).catch(error => {
        this.songloging = false;
        this.snackbar = true;
        this.text = "获取歌曲列表失败！" + error.response.data;
      });
    },
    templateText(text) {
      this.input = text;
    },
    changeTheCopy() {
      // 创建一个数组副本
      let buttonsCopy = [...this.buttons];

      // 随机选择三个元素
      let selectedButtons = [];
      for (let i = 0; i < 3; i++) {
        let randomIndex = Math.floor(Math.random() * buttonsCopy.length);
        selectedButtons.push(buttonsCopy[randomIndex]);
        buttonsCopy.splice(randomIndex, 1); // 移除已选中的元素
      }
      this.button = selectedButtons;
    },
    playAudio(index, card, src) {

      
      const globalAudio = this.$globalAudio;

      if (card.status === 0) {
        this.snackbar = true;
        this.text = '音乐生成中，请稍后~';
        return;
      }

      if (card.status === -1) {
        this.snackbar = true;
        this.text = '抱歉，失败任务无法操作，您可以尝试重新生成作品~';
        return;
      }

      //检测是否点击同一曲目且是否在播放中
      if (this.$globalObject.typeId === card.typeId && this.$globalObject.id === card.id && !globalAudio.paused) {
        globalAudio.pause();
        if (this.$globalObject.typeId === 3) {
          for (let i = 0; i < this.musicCards.length; i++) {
            if (this.musicCards[i].id === this.$globalObject.id) {
              this.musicCards[i].icon = 'mdi-play';
              return;
            }
          }
        }
      }

      if (this.$globalObject.typeId === card.typeId && this.$globalObject.id === card.id && globalAudio.paused) {
        globalAudio.play().catch(err => {
        });
        if (this.$globalObject.typeId === 3) {
          for (let i = 0; i < this.musicCards.length; i++) {
            if (this.musicCards[i].id === this.$globalObject.id) {
              this.musicCards[i].icon = 'mdi-pause';
              return;
            }
          }
        }
      }


      for (let i = 0; i < this.musicCards.length; i++) {
        if (this.$globalObject.typeId === 3) {
          for (let i = 0; i < this.musicCards.length; i++) {
            
            if (this.musicCards[i].id === this.$globalObject.id) {
              this.musicCards[i].icon = 'mdi-play';
              break;
            }
          }
        }
      }

      this.$globalObject.typeId = card.typeId;
      this.$globalObject.id = card.id;
      this.$globalObject.index = index;
      this.$globalObject.audio = src;
      this.$globalObject.image = card.image;
      this.$globalObject.title = card.title;
      this.$globalObject.subtitle = card.subtitle;



      // 停止全局音频
      globalAudio.pause();
      globalAudio.currentTime = 0; // 重置播放位置
      globalAudio.src = ''; // 清空 src，防止自动播放      

      globalAudio.load(); // 重新加载音频
      // 设置新的音频源
      globalAudio.src = src;
      globalAudio.load();

      // 开始播放
      globalAudio.play().catch(err => {
          
      });


      //重置播放列表
      for (let i = 0; i < this.musicCards.length; i++) {
        if (this.musicCards[i].status === 0) {
          break;
        }
        if(this.musicCards[i].status===-1){
              this.musicCards[i].icon = 'mdi-alert-circle';
              break;
        }
        this.musicCards[i].icon = 'mdi-play';
      }

      //首次播放更换图标
      if (this.$globalObject.typeId === 3) {
        for (let i = 0; i < this.musicCards.length; i++) {
          if (this.musicCards[i].id === card.id) {
            this.musicCards[i].icon = 'mdi-pause';
            break;
          }
        }
      }

      // 添加事件监听器
      globalAudio.addEventListener('ended', () => {
        if (this.$globalObject.typeId === 3) {
          for (let i = 0; i < this.musicCards.length; i++) {
            if (this.musicCards[i].id === card.id) {
              this.musicCards[i].icon = 'mdi-play';
              break;
            }
          }
        }
      });
    },
    task(id, card) {
      //删除音乐
      if(id===6){
        this.delSongId = card.id;
        this.dialog = true;
        return;
      }
      
      
      if (card.status === 0) {
        this.snackbar = true;
        this.text = '音乐生成中，请稍后~';
        return;
      }

      if (card.status === -1) {
        this.snackbar = true;
        this.text = '仅支持已完成的作品';
        return;
      }

      

      //分享
      if (id === 5) {
        this.copyToClipboard(card.status, card);
        return;
      }

        // 生成类似
        if(id===2){
            this.generateSimilar(card.title,card.subtitle);
      }
      
      //关闭背景
      if (id === 4) {
        for (let i = 0; i < this.musicCards.length; i++) {
          if (this.musicCards[i].id === card.id) {
            this.musicCards[i].image = '';
            return;
          }
        }
      }


      //下载音频
      if (id === 1) {
        this.snackbar = true;
        this.text = '已提交下载任务，请稍后~';

        if (!card.audio) {
          this.snackbar = true;
          this.text = '音频文件URL未提供，请联系客服';
          return;
        }

        fetch(card.audio)
          .then(response => {
            if (!response.ok) {
              this.snackbar = true;
              this.text = '请求失败，请检查您的网络连接！';
              throw new Error('网络请求失败');
            }
            return response.blob();
          })
          .then(blob => {
            // 创建 Blob 对象
            const url = window.URL.createObjectURL(blob);

            // 创建临时链接
            const a = document.createElement('a');
            a.href = url;
            a.download = card.title + '.mp3'; // 设置下载文件名

            // 触发点击事件
            document.body.appendChild(a);
            a.click();

            // 清除临时链接
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);

          })
          .catch(error => {
            this.snackbar = true;
            this.text = '下载失败：' + error;
          });
      }

    },
    copyToClipboard(status, card) {
      if (status === -1) {
        this.snackbar = true;
        this.text = '无法分享失败的作品';
        return;
      }
      
      
      if (status === 0) {
        this.snackbar = true;
        this.text = '音乐生成中，请稍后~';
        return;
      }

      let texts = '我在sunosong分享了一首歌曲，快来听听吧：' + this.$globalUrl + '?id=' + this.toBase64(card.id);

      if (navigator.clipboard && navigator.clipboard.writeText) {
        // 使用现代浏览器的 clipboard API
        navigator.clipboard.writeText(texts).then(() => {
          this.snackbar = true;
          this.text = '复制链接成功，快去分享给您的好友吧！';
        }, (err) => {
          this.snackbar = true;
          this.text = '复制链接失败:' + err;
        });
      } else {
        // 兼容旧版浏览器
        const textarea = document.createElement('textarea');
        textarea.value = texts;
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand('copy');
          this.snackbar = true;
          this.text = '复制链接成功，快去分享给您的好友吧！';
        } catch (err) {
          this.snackbar = true;
          this.text = '复制链接失败:' + err;
        }
        document.body.removeChild(textarea);
      }
    }

  },

  mounted() {
    window.addEventListener('resize', this.updateWindowDimensions);

  },

  beforeUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  },
  beforeDestroy() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  },
}
</script>

<style scoped>
.text-left {
  margin-bottom: 20px;
}

.v-btn {
  margin-top: 0;
}

.bt-text {
  font-size: 15px;
  white-space: pre-wrap;
  word-break: break-word;
}

.music-type {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}

.show-more {
  margin-top: 18px;
  color: #000000;
  font-size: 15px;
  text-align: right;
}

.container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.red {
  color: rgb(228, 169, 158);
  /* 设置文本颜色为红色 */
}

.grey {
  color: rgb(255, 255, 255);
  /* 设置文本颜色为白色 */
}

.cardTitle{
  background-color: rgba(0, 0, 0, 1);
  padding: 4px 8px;
  opacity: 1; 
}

.cardActions{
  background-color: rgba(0, 0, 0, 1);
  opacity: 1; 
}

.cardPlay{
  background-color: rgba(0, 0, 0, 1);
  border-radius: 999px; 
  padding: 4px 8px;
  opacity: 1; 
}


</style>