<template>
    <v-container fluid class="home-container">
      <v-row align="center" justify="center" :class="windowWidth>768?mt-10:mt-0">
        <v-col cols="12" md="8">
          <v-card elevation="0" class="text-left pa-6">
            <v-card-title class="display-2 font-weight-bold mb-4 d-flex align-center">
              <v-icon color="#54FF9F" :size="windowWidth>768?72:32">mdi-music-note</v-icon>
              <span class="ml-4">SUNO中文版</span>
            </v-card-title>
            <v-card-subtitle class="headline font-weight-bold mt-4 mb-4 d-flex align-center">
              <v-icon color="#54FF9F" :size="windowWidth>768?72:32">mdi-music-box-outline</v-icon>
              <span class="ml-4">AI音乐创作工具</span>
            </v-card-subtitle>
  
            <v-divider></v-divider>
            <v-card-text class="text-justify">
              &emsp;我们是专注于工具类应用程序的开发团队，基于最前沿的SUNO AI 3.5 技术，打造了这个创新的音乐生成平台，旨在为音乐制作人、音乐爱好者及追求独特音乐体验的用户带来全新的创作空间。
            </v-card-text>
            <v-card-text class="text-justify">
              &emsp;借助先进的深度学习算法，无论是初学者还是经验丰富的音乐专业人士，都可以根据自己的需求定制出满足要求的音乐作品。只需简单几步，选择心仪的音乐风格、节奏和音色，SUNO AI 将为您智能生成独一无二的音乐作品。
            </v-card-text>
            <!-- <v-divider></v-divider>
            <v-row align="center" justify="center" class="mt-5 mb-5">
            <v-col cols="auto">
              <v-btn size="x-large" color="#000" @click="$router.push('/create')">马上开始</v-btn>
            </v-col>
          </v-row> -->
          <v-divider></v-divider>
            <v-row align="center" justify="center" class="mt-5">
              <p class="">&copy; 2024 sunosong.cn. 保留所有权利。</p>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'BriefIntroduction',
    data() {
      return {
        windowWidth: window.innerWidth,
      };
    },
    methods: {
      updateWindowDimensions() {
        this.windowWidth = window.innerWidth;
      }
    },
    mounted() {
      window.addEventListener('resize', this.updateWindowDimensions);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }
  };
  </script>
  
  <style scoped>
  .home-container {
    background: linear-gradient(135deg, #e9dcb9, #b8ee7a);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    .home-container {
    background: linear-gradient(135deg, #e9dcb9, #b8ee7a);
    min-height: 0vh;
    display: flex;
    align-items: center;
    justify-content: center;
    }
  }
  
  .display-2, .headline {
    font-size: 72px !important;
    line-height: 1.2;
    color: #333;
    margin-bottom: 20px;
  }
  
  .text-justify {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
    text-align: justify;
  }
  
  .v-card {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
  }
  
  @media (max-width: 768px) {
    .display-2, .headline {
      font-size: 32px !important;
    }
  
    .text-justify {
      font-size: 16px;
      line-height: 1.4;
    }
  
    .v-icon {
      font-size: 32px !important;
    }
  }
  </style>