<template>
    <login-dialog :open="showLoginDialog" @loginSuccess="handleLoginSuccess" @cancellation="handleCancellation" />
    <v-dialog
      v-model="dialog"
      width="auto"
    >
      <v-card
        text="删除无法恢复，要继续吗？"
        title="温馨提示："
      >
        <template v-slot:actions>
          <v-btn
            text="取消"
            class="text-green" min-width="92" variant="text" 
            @click="dialog = false"
          ></v-btn>
          <v-btn
            text="确定"
            class="text-red" min-width="92" variant="text" 
            @click="delSong"
          ></v-btn>
        </template>
      </v-card>
    </v-dialog>

  <div>
    <!-- 消息提示 -->
    <v-snackbar :timeout="2000" v-model="snackbar" color="white" vertical>
      <div class="text-subtitle-1 pb-2">提示</div>
      <p>{{ text2 }}</p>

      <template v-slot:actions>
        <v-btn color="pink" variant="text" @click="snackbar = false">
          关闭
        </v-btn>
      </template>
    </v-snackbar>
    <v-sheet elevation="3" rounded="lg">
      <v-tabs v-model="tab" :items="tabs" color="black" height="80" slider-color="#f78166" align-tabs="center">
        <template v-slot:tab="{ item }">
          <v-tab :prepend-icon="item.icon" :text="item.text" :value="item.value"
            class="text-none text-subtitle-1"></v-tab>
        </template>

        <template v-slot:item="{ item }">
          <v-tabs-window-item :value="item.value" class="pa-4">
            <v-container fluid>
              <v-row justify="space-between" align="center">
                <v-col cols="auto">
                  <v-tooltip text="开启则生成仅有乐器无人声的音乐" location="top">
                    <template v-slot:activator="{ props }">
                      <v-switch v-model="item.attribute.absoluteMusic" label="纯音乐" false-value=0 true-value=1
                        hide-details v-bind="props" color="#9BCD9B" width="150" inset>
                      </v-switch>
                    </template>
                  </v-tooltip>
                </v-col>
                <v-col cols="auto">
                  <v-btn v-if="windowWidth > 1280 && !drawerVisiblePc" variant="outlined"
                    @click="drawerVisiblePc = !drawerVisiblePc"><v-icon>mdi-music-circle</v-icon>音乐列表</v-btn>
                  <v-btn v-if="windowWidth < 1280" variant="outlined"
                    @click="drawerVisible = !drawerVisible"><v-icon>mdi-music-circle</v-icon>音乐列表</v-btn>
                </v-col>
              </v-row>
            </v-container>


            <!-- 歌曲描述 -->
            <div v-if="item.value === 1" class="ml-5">

              歌曲描述：
              <v-textarea label="请描述你想要的音乐风格和主题" variant="outlined" class="mt-2"
                v-model="item.attribute.lyricsDescription">
              </v-textarea>
              <v-btn @click="randomPrompt" class="mb-2" variant="text">来点灵感？</v-btn>
            </div>

            <!-- 继续生成 -->
            <div>
            </div>

            <!-- 歌手性别 -->
            <div v-if="item.value === 3" class="ml-5">
              歌手性别：
              <v-chip-group v-model="item.attribute.gender" variant="outlined" filter>
                <v-chip>随机</v-chip>
                <v-chip>男声</v-chip>
                <v-chip>女声</v-chip>
              </v-chip-group>


            </div>

            <div v-if="item.value === 3" class="mt-10 ml-5">
              歌曲名称：
              <v-textarea label="请输入歌曲名称" v-model="item.attribute.songName" row-height="15" rows="1" variant="outlined"
                auto-grow class="mt-2 mb-2"></v-textarea>
            </div>

            <div v-if="item.value === 2 || item.value === 3">
              <v-container class="ml-2" fluid>
                <v-row justify="space-between" align="center">
                  <v-col cols="auto">
                    歌词：
                  </v-col>
                  <v-col cols="auto">
                    <v-btn variant="outlined" :loading="item.loading"
                      @click="generateLyric(item.value, item.attribute.songName)">AI生成歌词</v-btn>
                  </v-col>
                </v-row>
              </v-container>
              <v-textarea label="请输入歌词" variant="outlined" class="mt-2 mb-2 ml-5"
                v-model="item.attribute.lyric"></v-textarea>
            </div>

            <div v-if="item.value === 3" class="ml-5">
              音乐风格:
              <div>
                <v-tabs v-model="item.attribute.model" bg-color="white" slider-color="teal-lighten-3" show-arrows>
                  <v-tab v-for="(musicGenre, index) in item.attribute.musicGenres" :key="index" :text="musicGenre.type"
                    :value="musicGenre.type">

                  </v-tab>
                </v-tabs>

                <v-tabs-window v-model="item.attribute.model">
                  <v-tabs-window-item v-for="(musicGenre, index) in item.attribute.musicGenres" :key="index"
                    :value="musicGenre.type">
                    <v-chip-group v-model="musicGenre.index" column multiple>
                      <v-chip v-for="(g, i) in musicGenre.genre" :key="i" :text="g" variant="outlined"
                        @click="selectMusicGenres(index, i, g)" filter></v-chip>
                    </v-chip-group>
                  </v-tabs-window-item>
                </v-tabs-window>
              </div>

              <v-card-text>
                <div v-if="item.attribute.musicGenre.length > 0">
                  <span>已选择风格:</span>
                  <span v-for="(m, j) in item.attribute.musicGenre" :key="j">
                    <span class="ml-1 mb-1">{{ m.name }}
                      <span style="color: red;" @click="removeMusicGenres(j)">
                        ×
                      </span>
                    </span>
                  </span>
                </div>

              </v-card-text>
            </div>

            <div v-if="item.value === 3" class="ml-5">
              自定义风格：
              <v-textarea label="多个音乐风格请用逗号隔开" v-model="item.attribute.musicStyle" row-height="15" rows="1"
                variant="outlined" auto-grow class="mt-2 mb-2"></v-textarea>
            </div>

            <div class="d-flex justify-center align-center">
              <v-btn :loading="loading" variant="outlined" size="x-large" @click="load(item.value)"
                class="custom-button">
                <v-icon class="mt-1">mdi-music</v-icon>生成音乐
              </v-btn>
            </div>
          </v-tabs-window-item>
        </template>
      </v-tabs>
    </v-sheet>
  </div>

  <!-- 电脑端 -->
  <v-navigation-drawer v-if="windowWidth > 1280" location="right" width="500" v-model="drawerVisiblePc">
    <div class="ma-3" style="display: flex;justify-content: space-between;">
      <span class="mt-2" style="text-align: left;"><v-icon class="mb-1 mr-1">mdi-music-circle</v-icon>音乐列表</span>
      <span style="text-align: right;"><v-btn variant="outlined"
          @click="drawerVisiblePc = !drawerVisiblePc">收起列表</v-btn></span>
    </div>
    <v-divider class="border-opacity-100"></v-divider>
    <v-empty-state v-if="inProgress.length === 0 && songloging === false && isNetworkError === false" icon="mdi-magnify"
      text="快去创作您的第一首曲目吧！" title="空空如也"></v-empty-state>

    <v-empty-state v-if="inProgress.length === 0 && songloging === true && isNetworkError === false"
      icon="mdi-spin mdi-loading" title="加载中" text="正在奋力加载，精彩马上呈现~"></v-empty-state>

    <v-empty-state v-if="inProgress.length === 0 && songloging === false && isNetworkError === true" icon="mdi-cancel"
      title="加载失败" text="网络错误，请检查您的网络连接"></v-empty-state>
    <v-list v-if="inProgress.length > 0">
      <v-list-item v-for="(item, index) in inProgress" :key="index">
        <v-card color="#616161" class="mt-3" :image="item.image">
          <div>
            <div @click="spkiPlay(item, inProgress)">
              <v-card-title :class="item.status===1?'cardTitle mb-3 text-h5 d-flex justify-space-between align-center':'text-h5 d-flex justify-space-between align-center'">
    <span >
      {{ truncateSubtitle2(item.title) }}
    </span>
    <v-switch
    v-if="item.status===1 || item.status===0"
    :model-value="item.model"
    :label="`${item.model ? '公开' : '私有'}`"
    color="green"
    hide-details
    @click.stop="open(index)"
  ></v-switch>

  <v-switch
  v-else
  v-model="item.model"
    label="失败"
    color="primary"
    false-value="yes"
    true-value="no"
    hide-details
    @click.stop=""
    disabled
        ></v-switch>
  </v-card-title>
              <v-card-subtitle style="opacity: 1;" v-if="item.status === 1"><span :class="item.status===1?'cardTitle':''">{{truncateSubtitle(translateMusicGenreToChinese(item.subtitle))}}</span></v-card-subtitle>
              <v-card-subtitle style="opacity: 1;" v-else><span :class="item.status===1?'cardTitle':''">{{item.subtitle}}</span></v-card-subtitle>
              <v-card-actions>
                <v-btn :class="item.status === 1? 'ml-4 cardPlay':''" :icon="item.status === 0 ? 'mdi-spin mdi-loading' : item.status === -1 ? 'mdi-alert-circle' : 'mdi-play'"></v-btn>
              </v-card-actions>
              <!-- 功能按钮区 -->
              <v-card-actions class="cardActions">
                <v-btn @click.stop="likeMusic(index)" :class="{ 'red': item.liked, 'grey': !item.liked }"><v-icon
                    class="mb-1">mdi-thumb-up</v-icon>
                  <!-- {{ formatNumber(item.likes) }} -->
                </v-btn>
                <v-btn><v-icon class="mb-1">mdi-headphones</v-icon>{{ formatNumber(item.play) }}</v-btn>
                <v-btn @click.stop="copyToClipboard(item)"><v-icon class="mb-1">mdi-share</v-icon>
                  <!-- {{ formatNumber(item.forwardCount) }} -->
                </v-btn>
                <!-- 卡片功能菜单 -->
                <v-menu :location="location">
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props">mdi-menu</v-icon>
                  </template>

                  <v-list>
                    <v-list-item v-for="(item2, index) in cardMenu" :key="index">
                      <v-list-item-title>
                        <v-btn @click.stop="task(item2.id, item)">
                          <v-icon>{{ item2.icon }}</v-icon>
                          {{ item2.title }}
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-actions>
            </div>

          </div>
        </v-card>
      </v-list-item>
      <v-pagination v-if="inProgress.length > 0 && totalPages > 1" v-model="page" :length="totalPages"
        next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @click="onPageChange"></v-pagination>
    </v-list>
  </v-navigation-drawer>


  <!-- 手机端 -->
  <v-navigation-drawer v-else location="right" width="300" v-model="drawerVisible">
    <div class="ma-3" style="display: flex;justify-content: space-between;">
      <span class="mt-2" style="text-align: left;"><v-icon class="mb-1 mr-1">mdi-music-circle</v-icon>音乐列表</span>
      <span style="text-align: right;"><v-btn variant="outlined"
          @click="drawerVisible = !drawerVisible">收起列表</v-btn></span>
    </div>
    <v-divider class="border-opacity-100"></v-divider>
    <v-empty-state v-if="inProgress.length === 0 && songloging === false && isNetworkError === false" icon="mdi-magnify"
      text="快去创作您的第一首曲目吧！" title="空空如也"></v-empty-state>

    <v-empty-state v-if="inProgress.length === 0 && songloging === true && isNetworkError === false"
      icon="mdi-spin mdi-loading" title="加载中" text="正在奋力加载，精彩马上呈现~"></v-empty-state>

    <v-empty-state v-if="inProgress.length === 0 && songloging === false && isNetworkError === true" icon="mdi-cancel"
      title="加载失败" text="网络错误，请检查您的网络连接"></v-empty-state>
    <v-list v-if="inProgress.length > 0">
      <v-list-item v-for="(item, index) in inProgress" :key="index">
        <v-card color="#616161" class="mt-3" :image="item.image">
          <div>
            <div @click="spkiPlay(item, inProgress)">
              <v-card-title :class="item.status===1?'cardTitle mb-3 text-h5 d-flex justify-space-between align-center':'text-h5 d-flex justify-space-between align-center'">
    <span >
      {{ truncateSubtitle2(item.title) }}
    </span>
    <v-switch
    v-if="item.status===1 || item.status===0"
    :model-value="item.model"
    :label="`${item.model ? '公开' : '私有'}`"
    color="green"
    hide-details
    @click.stop="open(index)"
  ></v-switch>

  <v-switch
  v-else
  v-model="item.model"
    label="失败"
    color="primary"
    false-value="yes"
    true-value="no"
    hide-details
    @click.stop=""
    disabled
        ></v-switch>
  </v-card-title>
              <v-card-subtitle style="opacity: 1;" v-if="item.status === 1"><span :class="item.status===1?'cardTitle':''">{{truncateSubtitle(translateMusicGenreToChinese(item.subtitle))}}</span></v-card-subtitle>
              <v-card-subtitle style="opacity: 1;" v-else><span :class="item.status===1?'cardTitle':''">{{item.subtitle}}</span></v-card-subtitle>
              <v-card-actions>
                <v-btn :class="item.status === 1? 'ml-4 cardPlay':''" :icon="item.status === 0 ? 'mdi-spin mdi-loading' : item.status === -1 ? 'mdi-alert-circle' : 'mdi-play'"></v-btn>
              </v-card-actions>
              <!-- 功能按钮区 -->
              <v-card-actions class="cardActions">
                <v-icon @click.stop="likeMusic(index)"
                  :class="{ 'red': item.liked, 'grey': !item.liked }">mdi-thumb-up</v-icon>
                <!-- {{ formatNumber(item.likes) }} -->
                <v-icon>mdi-headphones</v-icon>{{ formatNumber(item.play) }}
                <v-icon @click.stop="copyToClipboard(item)">mdi-share</v-icon>
                <!-- {{ formatNumber(item.forwardCount) }} -->
                <!-- 卡片功能菜单 -->
                <v-menu :location="location">
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props">mdi-menu</v-icon>
                  </template>

                  <v-list>
                    <v-list-item v-for="(item2, index) in cardMenu" :key="index">
                      <v-list-item-title>
                        <v-btn @click.stop="task(item2.id, item)">
                          <v-icon>{{ item2.icon }}</v-icon>
                          {{ item2.title }}
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-actions>


            </div>



          </div>
        </v-card>
      </v-list-item>
      <v-pagination v-if="inProgress.length > 0 && totalPages > 1" v-model="page" :length="totalPages"
        next-icon="mdi-menu-right" prev-icon="mdi-menu-left" :total-visible="1" @click="onPageChange"></v-pagination>
    </v-list>
  </v-navigation-drawer>

</template>

<script>
import LoginDialog from '../components/LocalDialog.vue';

export default {
  name: 'Create',
  components: {
    LoginDialog,
  },
  data: () => ({
    //音乐列表的隐藏和收起
    drawerVisible: false,
    drawerVisiblePc: true,
    snackbar: false,
    songloging: false,
    showLoginDialog: false,
    isNetworkError: false,
    remainingTime: 0, // 倒计时
    timerConditions: true,
    countdownInterval: null,
    text2: '',
    page: 1,
    dialog: false,
    delSongId:0,
    totalPages: 1,
    //窗口大小
    windowWidth: window.innerWidth,
    prompts: [
        "一首关于追逐梦想的歌曲。无论前方有多少困难，都要坚持自己的梦想。歌曲以轻快的钢琴旋律和温暖的弦乐为背景，旋律优美，传递出对梦想的执着与希望。",
        "一首关于真挚友情的歌曲。在人生的旅途中，朋友的支持和陪伴是最宝贵的财富。歌曲以温馨的吉他弹奏和柔和的和声为主，旋律温暖，传递出对友情的感激与珍惜。",
        "一首关于勇敢爱的歌曲。即使面对未知的未来，也要有勇气去爱。歌曲以深情的钢琴演奏和激情的鼓点为背景，旋律动人，传递出对爱情的坚定与勇敢。",
        "一首关于自我成长的歌曲。在不断的努力和挑战中，逐渐成为更好的自己。歌曲以动感的电子音乐和有力的贝斯为主，旋律激昂，传递出对自我提升的信念与决心。",
        "一首关于家庭温暖的歌曲。家是永远的港湾，无论外面的世界多么复杂，家总是最温暖的地方。歌曲以温柔的吉他弹奏和悠扬的小提琴为主，旋律温馨，传递出对家庭的依恋与感恩。",
        "一首关于自由飞翔的歌曲。像鸟儿一样在天空中自由翱翔，无拘无束。歌曲以清新的吉他和空灵的合成器为主，旋律轻盈，传递出对自由的向往与渴望。",
        "一首关于克服困难的歌曲。面对生活的挫折，依然能够坚强地站起来。歌曲以激昂的鼓点和有力的贝斯为主，旋律振奋，传递出对逆境的不屈与抗争。",
        "一首关于心灵治愈的歌曲。在疲惫的心灵中找到一片宁静。歌曲以舒缓的钢琴和温暖的弦乐为主，旋律平静，传递出对内心的抚慰与安慰。",
        "一首关于青春回忆的歌曲。回忆那些美好的时光，那些曾经的梦想。歌曲以轻柔的吉他和温暖的和声为主，旋律怀旧，传递出对青春的怀念与感慨。",
        "一首关于旅行的歌曲。在路上，感受不同的风景和文化。歌曲以欢快的节奏和明亮的旋律为主，传递出对旅行的热爱与期待。",
        "一首关于感恩的歌曲。感谢生命中的每一个人，每一段经历。歌曲以温暖的钢琴和弦乐为主，旋律感人，传递出对生活的感激与珍惜。",
        "一首关于孤独的歌曲。在孤独中寻找自我，学会独立。歌曲以深沉的钢琴和低沉的贝斯为主，旋律忧伤，传递出对孤独的理解与接纳。",
        "一首关于希望的歌曲。即使在黑暗中，也要相信光明终将到来。歌曲以明亮的吉他和激昂的鼓点为主，旋律充满力量，传递出对未来的信心与希望。",
        "一首关于自然的歌曲。感受大自然的美丽与神奇。歌曲以清新的吉他和自然的音效为主，旋律清新，传递出对自然的敬畏与赞美。",
        "一首关于梦想实现的歌曲。经过努力，终于实现了自己的梦想。歌曲以欢快的节奏和明亮的旋律为主，传递出对成功的喜悦与自豪。",
        "一首关于失恋的歌曲。虽然心痛，但也要学会放手。歌曲以悲伤的钢琴和低沉的贝斯为主，旋律哀伤，传递出对失恋的痛苦与释然。",
        "一首关于友情长存的歌曲。即使时间流逝，友情依然如初。歌曲以温暖的吉他和柔和的和声为主，旋律温馨，传递出对友情的永恒与珍贵。",
        "一首关于奋斗的歌曲。为了目标，不断努力。歌曲以激昂的鼓点和有力的贝斯为主，旋律振奋，传递出对奋斗的坚定与热情。",
        "一首关于梦想启航的歌曲。从零开始，勇敢追梦。歌曲以轻快的吉他和明亮的旋律为主，传递出对梦想的憧憬与决心。",
        "一首关于爱与和平的歌曲。希望世界充满爱与和平。歌曲以温暖的钢琴和和谐的和声为主，旋律优美，传递出对美好世界的向往与期盼。",
        "一首关于勇敢表达自己的歌曲。勇敢做自己，不被他人左右。歌曲以充满动力的鼓点和激昂的吉他为主，旋律振奋，传递出对自我表达的鼓励与支持。",
        "一首关于心灵平静的歌曲。在喧嚣的世界中找到内心的宁静。歌曲以轻柔的钢琴和温暖的弦乐为主，旋律平和，传递出对内心的宁静与安宁。",
        "一首关于亲情的歌曲。家人的爱和支持是最坚实的后盾。歌曲以温暖的吉他和柔和的和声为主，旋律温馨，传递出对亲情的感激与珍惜。",
        "一首关于梦想破灭的歌曲。面对失败，也要学会重新站起来。歌曲以悲伤的钢琴和低沉的贝斯为主，旋律哀伤，传递出对失败的反思与重生。",
        "一首关于友情破裂的歌曲。即使友情不再，也要学会放下。歌曲以忧伤的吉他和低沉的贝斯为主，旋律忧伤，传递出对友情破裂的痛苦与释然。",
        "一首关于爱情失去的歌曲。失去爱情后，也要学会重新开始。歌曲以悲伤的钢琴和低沉的贝斯为主，旋律哀伤，传递出对失去爱情的痛苦与释然。",
        "一首关于自我救赎的歌曲。在困境中找到自我，实现自我救赎。歌曲以激昂的鼓点和有力的贝斯为主，旋律振奋，传递出对自我救赎的坚定与勇气。",
        "一首关于梦想坚持的歌曲。即使前路坎坷，也要坚持到底。歌曲以激昂的鼓点和明亮的吉他为主，旋律振奋，传递出对梦想的坚持与决心。",
        "一首关于友情重燃的歌曲。失去的友情可以重新找回。歌曲以温暖的吉他和柔和的和声为主，旋律温馨，传递出对友情重燃的喜悦与感激。",
        "一首关于爱情重来的歌曲。失去的爱情可以重新开始。歌曲以温暖的钢琴和柔和的和声为主，旋律温馨，传递出对爱情重来的喜悦与希望。",
        "一首关于自我发现的歌曲。在探索中发现真正的自己。歌曲以轻柔的吉他和温暖的和声为主，旋律温馨，传递出对自我发现的喜悦与满足。",
        "一首关于梦想实现的歌曲。经过不懈努力，终于实现了自己的梦想。歌曲以欢快的节奏和明亮的旋律为主，传递出对成功的喜悦与自豪。",
        "一首关于心灵成长的歌曲。在经历中不断成长，变得更加坚强。歌曲以温暖的钢琴和柔和的和声为主，旋律温馨，传递出对心灵成长的喜悦与满足。",
        "一首关于梦想破灭后的重生的歌曲。即使梦想破灭，也要重新站起来。歌曲以激昂的鼓点和明亮的吉他为主，旋律振奋，传递出对重生的坚定与勇气。",
        "一首关于友情长存的歌曲。即使时间流逝，友情依然如初。歌曲以温暖的吉他和柔和的和声为主，旋律温馨，传递出对友情的永恒与珍贵。",
        "一首关于爱情永恒的歌曲。真爱永不消逝，永远陪伴在身边。歌曲以温暖的钢琴和柔和的和声为主，旋律温馨，传递出对爱情的永恒与美好。",
        "一首关于自我超越的歌曲。在挑战中超越自我，实现更高的目标。歌曲以激昂的鼓点和有力的贝斯为主，旋律振奋，传递出对自我超越的坚定与勇气。",
        "一首关于梦想追求的歌曲。无论前方有多少困难，都要坚持自己的梦想。歌曲以轻快的钢琴旋律和温暖的弦乐为背景，旋律优美，传递出对梦想的执着与希望。",
        "一首关于心灵治愈的歌曲。在疲惫的心灵中找到一片宁静。歌曲以舒缓的钢琴和温暖的弦乐为主，旋律平静，传递出对内心的抚慰与安慰。",
        "一首关于青春回忆的歌曲。回忆那些美好的时光，那些曾经的梦想。歌曲以轻柔的吉他和温暖的和声为主，旋律怀旧，传递出对青春的怀念与感慨。",
        "一首关于旅行的歌曲。在路上，感受不同的风景和文化。歌曲以欢快的节奏和明亮的旋律为主，传递出对旅行的热爱与期待。",
        "一首关于感恩的歌曲。感谢生命中的每一个人，每一段经历。歌曲以温暖的钢琴和弦乐为主，旋律感人，传递出对生活的感激与珍惜。",
        "一首关于孤独的歌曲。在孤独中寻找自我，学会独立。歌曲以深沉的钢琴和低沉的贝斯为主，旋律忧伤，传递出对孤独的理解与接纳。"
      ],
    locations: [
      'top',
      'bottom',
      'start',
      'end',
      'center',
    ],
    location: 'end',
    tab: '',
    text: '',
    // 提交按钮默认不加载
    loading: false,
    cardMenu: [
      { id: 1, title: '下载音频', icon: 'mdi-download' },
      { id: 2, title: '生成类似', icon: 'mdi-refresh' },
      // { id: 3, title: '延长音频', icon: 'mdi-playlist-plus' },
      // { id: 4, title: '关闭背景', icon: 'mdi-image-off' },
      { id: 5, title: '音乐分享', icon: 'mdi-share' },
      { id: 6, title: '删除音乐', icon: 'mdi-delete' },

    ],
    inProgress: [
      //音乐列表
    ],
    tabs: [
      {
        icon: 'mdi-book-open-page-variant',
        text: '灵感模式',
        value: 1,
        attribute: {
          // 纯音乐
          absoluteMusic: 0,
          lyricsDescription: ''
        }
      },
      {
        icon: 'mdi-license',
        text: '自定义模式',
        value: 3,
        loading: false,
        attribute: {
          // 纯音乐
          absoluteMusic: 0,
          model: 'ttt',
          // 歌手性别 0为随机 1为男 2为女
          gender: [0],
          // 歌曲名称
          songName: '',
          //自定义风格
          musicStyle: '',
          // 歌词
          lyric: '',
          // 音乐流派
          musicGenre: [],
          //音乐风格
// 音乐风格
musicGenres: [
  { 
    type: '常用',
    genre: ['流行音乐', '中国节奏','中国民歌','韩国流行音乐', '日本流行音乐', '古风音乐', '迪斯科', '电子音乐', '爵士乐', '拉丁爵士', '乡村音乐', '民谣音乐', '探戈', '嘻哈', '说唱音乐', '欧美风', '经典摇滚', '重金属音乐']
  },
  { 
    type: '中国',
    genre: ['中国节奏','中国民歌']
  },
  { 
    type: '流行',
    genre: ['流行音乐', '流行舞曲', '流行摇滚', '韩国流行音乐', 'R&B', '合成流行', '古风音乐', '欧美风']
  },
  { 
    type: '拉丁',
    genre: ['博萨诺瓦', '拉丁爵士', '萨尔萨舞', '探戈']
  },
  { 
    type: '摇滚',
    genre: ['经典摇滚', '布鲁斯摇滚', '情绪', '华丽摇滚', '独立音乐', '工业摇滚', '朋克摇滚', '摇滚乐', '滑板摇滚', '滑板核', '另类摇滚', '硬摇滚', '后摇', '实验摇滚', '数码硬核', '电子民谣', '后朋克', '新浪潮', '情感硬核', '斯卡音乐']
  },
  { 
    type: '爵士',
    genre: ['比博普', '福音', '爵士乐', '拉丁爵士', 'R&B', '灵魂乐', '电子爵士']
  },
  { 
    type: '乡村',
    genre: ['草莓乐', '乡村音乐', '民谣音乐', '芝加哥蓝调', '德尔塔蓝调']
  },
  { 
    type: '舞曲',
    genre: ['阿弗罗-古巴', '流行舞曲', '迪斯科', '配音步', '放克音乐', '电子舞曲', '电子音乐', '高能量', '浩室音乐', '迷幻舞曲', 'D&B（鼓打贝斯）']
  },
  { 
    type: '城市音乐',
    genre: ['放克音乐', '嘻哈', 'R&B', '酸音乐', '说唱音乐', '陷阱音乐']
  },
  { 
    type: '雷鬼',
    genre: ['舞厅', '配音', '雷鬼音乐', '雷盖顿', '非洲节奏']
  },
  { 
    type: '缓拍',
    genre: ['环境音乐', '鼓打贝斯', '配音步', '电子音乐', '智能舞曲', '合成流行', '合成波', '技术音乐', '陷阱音乐']
  },
  { 
    type: '金属',
    genre: ['黑金属', '死亡金属', '节日重金属', '重金属音乐', '金属核', '民谣金属', '哥特摇滚']
  },
  { 
    type: '精准控制',
    genre: ['流行伤感情歌', '古风音乐', '中国传统古风音乐', '流行浪漫情歌', '伤感传统民谣', '甜蜜流行情歌', '经典流行说唱', '青春校园摇滚', '潮流电子爵士']
  },
  { 
    type: '古典',
    genre: ['古典音乐', '歌剧', '室内乐', '古代音乐']
  },
  { 
    type: '世界音乐',
    genre: ['世界音乐', '民族音乐', '古巴音乐', '博萨诺瓦']
  },
  { 
    type: '新世纪',
    genre: ['新世纪音乐']
  },
  { 
    type: '实验',
    genre: ['实验音乐', '实验摇滚']
  }
],
        }
      }

    ],
    neighborhoods: [],
    
  }),
  created() {
    this.songloging = true;
    //验证是否登录
    this.$axios.get('/music/test').then(response => {
      this.$axios.get('/music/song/listNumber').then(response => {
        if (response.data.data % 20 == 0) {
          this.totalPages = response.data.data / 20;
        } else {
          this.totalPages = (response.data.data / 20) + 1;
        }
      }).catch(error => { });
      this.startCountdown();
      this.getSongList();
    }).catch(error => {
      if (error.message === "Network Error") {
        this.text2 = "网络连接失败，请检查网络后重试！"
        this.isNetworkError = true;
        this.snackbar = true;
      }
      this.songloging = false;
    });


    if (this.$route.query.keywords) {
      this.tab=1
      this.tabs[0].attribute.lyricsDescription = this.base64ToString(this.$route.query.keywords);
    }

    if (this.$route.query.name && this.$route.query.style) {
      this.tab=3
      this.tabs[1].attribute.musicStyle = this.base64ToString(this.$route.query.style);
      this.tabs[1].attribute.songName = this.base64ToString(this.$route.query.name);
    }

    
  },
  watch: {

  },
  
  methods: {
    handleLoginSuccess(data) {
    },
    handleCancellation(data) {
      this.showLoginDialog = false;
    },
    delSong(){
      this.$axios.post('/music/deleteSong',{nativeId: this.delSongId}).then(response => {
        if(response.data.code===2000){
          this.dialog = false;
          this.text = '删除成功！';
          this.snackbar = true;
          //刷新页面
          location.reload();
        }
      }).catch(error => {
      });
    },
    randomPrompt() {
      let randomIndex = Math.floor(Math.random() * this.prompts.length);
      this.tabs[0].attribute.lyricsDescription = this.prompts[randomIndex];
    },
    generateSimilar(name,style) {
      this.tab=3
      this.tabs[1].attribute.musicStyle = style;
      this.tabs[1].attribute.songName = name;
    },
    base64ToString(base64) {
    // 使用 atob 将 Base64 字符串转换为 ArrayBuffer
    const binaryString = atob(base64);
    // 使用 TextDecoder 将 ArrayBuffer 转换为原始字符串
    return new TextDecoder("utf-8").decode(new Uint8Array([...binaryString].map(c => c.charCodeAt(0))));
},
    progress(time) {
      const t = (Date.now() - time) / 2000
      if(t < 0){
        return 0+"%";
      }
      if(t<=98){
        return t.toFixed(2)+"%";
      }
      if (t > 98 && t < 132) {
        return 99.00+"%";
      }
      return 99.00+"% 目前使用人数多，努力生成中~";
    },
  
    translateMusicGenre(str) {
      const parts = str.split(',');
      let newStr = '';
      for(let i = 0; i < parts.length; i++){
        if(this.$genreMap[parts[i]]){
          newStr+=this.$genreMap[parts[i]]+','
        }else{
          newStr+=parts[i]+','
        }
        
      }
      if (newStr.endsWith(',')) { 
        return newStr.slice(0, -1); // 返回去掉最后一个字符的字符串
      }
      return newStr;
    },
    async startCountdown() {
      this.countdownInterval = setInterval(() => {
        //是否在当前页面
        if (this.$route.name === 'create' && this.timerConditions) {
          this.remainingTime++;

          //每十秒执行一次
          if (this.remainingTime % 10 === 0) {
            let task = '';
            // 判断是否有未完成卡片
            for (let i = 0; i < this.inProgress.length; i++) {
              //如果客户端有未完成的任务
              if (this.inProgress[i].status === 0) {
                task = this.inProgress[i];
                this.inProgress[i].subtitle = '生成中(' + this.progress(this.inProgress[i].createdAt)+')'
              }
            }

            if (task === '') {
              this.timerConditions = false;
            } else {
              //获取服务器未完成任务数
              this.$axios.get('/music/song/generateNumber').then(response => {
                //如果服务器没有未完成任务
                if (response.data.data === 0) {
                  //重新获取音乐列表
                  this.getSongList();
                  //回收计时器
                  this.snackbar = true;
                  this.text2 = '恭喜！音乐已创作完成！快去欣赏一下吧~';
                }
              }).catch(error => { });

            }

          }
        } else {
          clearInterval(this.countdownInterval);
        }
      }, 1000);
    },
    onPageChange() {
      this.getSongList();
    },
    //格式化数字显示
    formatNumber(number) {
      if (number >= 10000) {
        return (number / 10000).toFixed(1) + 'w';
      }
      else if (number >= 1000) {
        return (number / 1000).toFixed(1) + 'k';
      } else {
        return number.toString();
      }
    },
    likeMusic(index) {
      //点赞功能
      if(this.inProgress[index].status===0){
        this.snackbar = true;
        this.text2 = '音乐生成中，请稍后~';
        return;
      }

      if(this.inProgress[index].status===-1){
        this.snackbar = true;
        this.text2 = '抱歉，作品生成失败，失败原因：服务器忙碌或者作品中包含敏感词汇，您可以删除该作品，并尝试重新生成作品';
        return;
      }
      
      if (!this.inProgress[index].liked) {
        this.inProgress[index].likes++;
        this.inProgress[index].liked = !this.inProgress[index].liked;
      } else {
        this.inProgress[index].likes--;
        this.inProgress[index].liked = !this.inProgress[index].liked;
      }

    },
    //跳转播放页面
    spkiPlay(card, cards) {
      if(card.status===0){
        this.snackbar = true;
        this.text2 = '音乐生成中，请稍后~';
        return;
      }

      if(card.status===-1){
        this.snackbar = true;
        this.text2 = '抱歉，作品生成失败，失败原因：服务器忙碌或者作品中包含敏感词汇，您可以删除该作品，并尝试重新生成作品';
        return;
      }

      
      let cardsId = card.id;
      for (let i = 0; i < cards.length; i++) {
        cardsId = cardsId + ',' + cards[i].id;
      }
      let cardId = this.toBase64(card.id);
      cardsId = this.toBase64(cardsId);
      this.$router.push({
        path: '/musicPlayer',
        query: { id: cardId, ids: cardsId }
      });
    },
    toBase64(str) {
      // 使用 TextEncoder 将字符串转换为 ArrayBuffer
      const buffer = new TextEncoder().encode(str);
      // 使用 btoa 将 ArrayBuffer 转换为 Base64 字符串
      return btoa(String.fromCharCode(...new Uint8Array(buffer)));
    },
    task(id, card) {
       //删除音乐
       if(id===6){
        this.delSongId = card.id;
        this.dialog = true;
        return;
      }
      
      if (card.status === 0) {
        this.snackbar = true;
        this.text2 = '音乐生成中，请稍后~';
        return;
      }

      if (card.status === -1) {
        this.snackbar = true;
        this.text2 = '抱歉，作品生成失败，失败原因：服务器忙碌或者作品中包含敏感词汇，您可以删除该作品，并尝试重新生成作品';
        return;
      }

      // 生成类似
      if(id===2){
          if(card.status===1){
            this.generateSimilar(card.title,card.subtitle);
          }else{
            this.snackbar = true;
            this.text = '仅支持已完成的作品';
            return;
          }
      }
      
      //分享
      if (id === 5) {
        let url = this.$globalUrl + '?id=' + this.toBase64(card.id);
        this.copyToClipboard(url);
        return;
      }

      //关闭背景
      if (id === 4) {
        for (let i = 0; i < this.inProgress.length; i++) {
          if (this.inProgress[i].id === card.id) {
            this.inProgress[i].image = '';
            return;
          }
        }
      }


      //下载音频
      if (id === 1) {
        
        this.snackbar = true;
        this.text2 = '已提交下载任务，请稍后~';

        if (!card.audio) {
          console.error('音频文件 URL 未提供');
          return;
        }

        fetch(card.audio)
          .then(response => {
            if (!response.ok) {
              throw new Error('网络请求失败');
            }
            return response.blob();
          })
          .then(blob => {
            // 创建 Blob 对象
            const url = window.URL.createObjectURL(blob);

            // 创建临时链接
            const a = document.createElement('a');
            a.href = url;
            a.download = card.title + '.mp3'; // 设置下载文件名

            // 触发点击事件
            document.body.appendChild(a);
            a.click();

            // 清除临时链接
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);

          })
          .catch(error => {
            console.error('下载失败:', error);
          });
      }

    },
    copyToClipboard(card) {
      if (card.status != 1) {
        this.snackbar = true;
        this.text2 = '音乐生成中，请稍后~';
        return;
      }

      let text = '我在sunosong分享了一首歌曲，快来听听吧：'+this.$globalUrl + '?id=' + this.toBase64(card.id);

      if (navigator.clipboard && navigator.clipboard.writeText) {
        // 使用现代浏览器的 clipboard API
        navigator.clipboard.writeText(text).then(() => {
          this.snackbar = true;
          this.text2 = '复制链接成功，快去分享给您的好友吧！';
        }, (err) => {
          this.snackbar = true;
          this.text2 = '复制文本失败:' + err.message;
          console.error('Failed to copy text: ', err);
        });
      } else {
        // 兼容旧版浏览器
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand('copy');
          this.snackbar = true;
          this.text2 = '复制链接成功，快去分享给您的好友吧！';
        } catch (err) {
          this.snackbar = true;
          this.text2 = '复制文本失败:' + err.message;
          console.error('Failed to copy text: ', err);
        }
        document.body.removeChild(textarea);
      }
    },
    truncateSubtitle(subtitle) {
      if (subtitle.length > 20) {
        return subtitle.slice(0, 20) + '...';
      }
      return subtitle;
    },
    truncateSubtitle2(subtitle) {
      if (subtitle.length > 5) {
        return subtitle.slice(0, 5) + '...';
      }
      return subtitle;
    },
    translateMusicGenreToChinese(str) {
      const parts = str.split(',');
      let newStr = '';
      for(let i = 0; i < parts.length; i++){
        if(this.$genreMapReversed[parts[i]]){
          newStr+=this.$genreMapReversed[parts[i]]+','
        }else{
          newStr+=parts[i]+','
        }
        
      }
      if (newStr.endsWith(',')) { 
        return newStr.slice(0, -1); // 返回去掉最后一个字符的字符串
      }
      return newStr;
    },
    async generateLyric(id, text) {

      if (id === 3) {
        if (!this.tabs[1].attribute.songName) {
          this.snackbar = true;
          this.text2 = "请先输入歌曲名称";
          return;
        }
        this.tabs[1].loading = true;
        try {
          const response = await this.$axios.post('/music/api/generateLyrics', { prompt: text });
          this.tabs[1].attribute.lyric = response.data.data.text;
          this.snackbar = true;
          this.text2 = "歌词生成成功";
        } catch (error) {
          this.snackbar = true;
          this.text2 = "生成歌词失败！请稍后重试";
        }

        this.tabs[1].loading = false;

        return;
      }

      if (id === 2) {
        if (!this.tabs[2].attribute.songName) {
          this.snackbar = true;
          this.text2 = "请先输入歌曲名称";
          return;
        }
        this.tabs[2].loading = true;
        try {
          const response = await this.$axios.post('/music/api/generateLyrics', { prompt: text });
          if (response.data.text == "Sorry\nBut I can't create that content for you. If you have another topic or a different kind of prompt in mind\nFeel free to let me know! I'm here to help with your songwriting needs" ||
            response.data.text == "I'm sorry\nBut that's not appropriate. Let's keep the prompts suitable for all audiences. How about writing a joyful song about a sunny day at the beach?"
          ) {
            this.snackbar = true;
            this.text2 = "歌词生成失败！请输入文明合规的歌曲名称";
            return;
          }
          this.tabs[2].attribute.lyric = response.data.text;
        } catch (error) {
          this.snackbar = true;
          this.text2 = "生成歌词失败！请稍后重试";
        }

        this.tabs[2].loading = false;
        return;
      }

    },
    //更新窗口大小
    updateWindowDimensions() {
      this.windowWidth = window.innerWidth;
    },
    selectMusicGenres(category, sect, name) {
      setTimeout(() => {
        //判断是选择还是取消选择
        if (this.tabs[1].attribute.musicGenres[category].index.includes(sect)) {
          //选择,将名字添加到数组
          if (this.tabs[1].attribute.musicGenre.length < 10) {
            this.tabs[1].attribute.musicGenre.push({
              name: name,
              category: category,
              sect: sect
            });
          } else {
            this.snackbar = true;
            this.text2 = "达到标签选择上限";
            //取消选中状态
            this.tabs[1].attribute.musicGenres[category].index.splice(this.tabs[1].attribute.musicGenres[category].index.indexOf(sect), 1);
          }

        } else {
          //取消选择
          //从数组中移除对象
          this.tabs[1].attribute.musicGenre.splice(this.tabs[1].attribute.musicGenre.indexOf(name), 1);
        }

      }, 1);
    },
    trimLastComma(str) {
      if (str.endsWith(',')) { // ES6 方法 endsWith 检查字符串是否以指定的子字符串结尾
        return str.slice(0, -1); // 返回去掉最后一个字符的字符串
      }
      return str; // 如果最后一个字符不是逗号，返回原字符串
    },
    removeMusicGenres(index) {
      let name = this.tabs[1].attribute.musicGenre[index].name;
      let category = this.tabs[1].attribute.musicGenre[index].category;
      let sect = this.tabs[1].attribute.musicGenre[index].sect;
      this.tabs[1].attribute.musicGenres[category].index.splice(this.tabs[1].attribute.musicGenres[category].index.indexOf(sect), 1);
      this.tabs[1].attribute.musicGenre.splice(index, 1);
    },
    open(index){
      const nativeId = this.inProgress[index].id;
      this.$axios.post('/music/openSong',{
        openId:this.inProgress[index].model?0:1,
        nativeId:nativeId        
      }).then(response => {
        this.snackbar=true;
        this.text2="状态修改成功！";
        this.inProgress[index].model = !this.inProgress[index].model;
      }).catch(error=>{
      })
      
    },
    //获取音乐列表
    async getSongList() {
      this.$axios.get('/music/song/list?page=' + this.page).then(response => {
        const songs = [];
        for (let i = 0; i < response.data.data.length; i++) {
          const data = response.data.data[i];
          songs.push({
            id: data.native_id === null ? 0 : data.native_id,
            typeId: 3,
            status: data.complete,
            title: data.complete === 1 ? data.title : data.complete === -1 ? '生成错误' : '生成中',
            subtitle: data.complete === 1 ? data.tags : data.complete === -1 ? '积分已退还' : '生成中('+this.progress(new Date(data.created_at).getTime())+')',
            image: data.complete === -1 ? '' : data.native_image_url,
            audio: data.native_audio_url,
            color: '#616161',
            icon: data.complete === 0 ? 'mdi-spin mdi-loading' : data.complete === -1 ? 'mdi-alert-circle' : 'mdi-play',
            likes: data.likeCount,
            play: data.play_count,
            createdAt: new Date(data.created_at).getTime(),
            forwardCount: data.forward_count,
            liked: false,
            model: data.open===1?true:false,
          });
        }
        this.inProgress = songs;
        this.songloging = false;
      }).catch(error => {
        this.songloging = false;
        this.snackbar = true;
        this.text2 = "获取歌曲列表失败！" + error.response.data;
      });
    },
    load(id) {
      this.loading = true

      const json = {
        action: "generate",
        prompt: "",
        model: "chirp-v3-5",
        lyric: "",
        custom: false,
        instrumental: false,
        title: "",
        style: "",
        audio_id: "",
        continue_at: 0.0
      }
      if (id === 1) {
        //判断是否输入了提示词
        if (this.tabs[0].attribute.lyricsDescription === '' || this.tabs[0].attribute.lyricsDescription === null) {
          this.snackbar = true;
          this.text2 = "请先输入歌词描述";
          this.loading = false;
          return;
        }

        if (this.tabs[0].attribute.lyricsDescription.length > 200) {
          this.snackbar = true;
          this.text2 = "歌词描述不能超过200字";
          this.loading = false;
          return;
        }
        this.tabs[0].attribute.absoluteMusic === 0 ? json.instrumental = false : json.instrumental = true;
        json.prompt = this.tabs[0].attribute.lyricsDescription;
      }


      if (id === 2) {
        if (this.tabs[2].attribute.songName === '' || this.tabs[2].attribute.songName === null) {
          this.snackbar = true;
          this.text2 = "请先输入歌曲名称";
          this.loading = false;
          return;
        }

        if (this.tabs[2].attribute.lyric === '' || this.tabs[2].attribute.lyric === null) {
          this.snackbar = true;
          this.text2 = "请先输入歌词";
          this.loading = false;
          return;
        }
        this.tabs[2].attribute.absoluteMusic === 0 ? json.instrumental = false : json.instrumental = true;

        json.lyric = this.tabs[2].attribute.lyric;
        json.style = this.tabs[2].attribute.musicStyle;
        json.title = this.tabs[2].attribute.songName;
        json.custom = true;
      }

      if (id === 3) {
        if (this.tabs[1].attribute.songName === '' || this.tabs[1].attribute.songName === null) {
          this.snackbar = true;
          this.text2 = "请输入歌曲名称";
          this.loading = false;
          return;
        }

        if (this.tabs[1].attribute.lyric === '' || this.tabs[1].attribute.lyric === null) {
          this.snackbar = true;
          this.text2 = "请输入歌词";
          this.loading = false;
          return;
        }
        
        if(this.tabs[1].attribute.lyric.length>2000){
          this.snackbar = true;
          this.text2 = "歌词不能超过2000个字符";
          this.loading = false;
          return;
        }
        
        
        if(this.tabs[1].attribute.songName.length>50){
          this.snackbar = true;
          this.text2 = "歌曲名称不能超过50个字符";
          this.loading = false;
          return;
        }

        if (this.tabs[1].attribute.gender === 1) {
          json.style += "Male Vocal,"
        }

        if (this.tabs[1].attribute.gender === 2) {
          json.style += "Female Vocal,"
        }

        if (this.tabs[1].attribute.gender === 3) {
          json.style += "Male and Female Duet,"
        }

        // 设置是否纯音乐
        this.tabs[1].attribute.absoluteMusic === 0 ? json.instrumental = true : json.instrumental = false;
        //设置歌词
        json.lyric = this.tabs[1].attribute.lyric;

        //设置风格
        for (let i = 0; i < this.tabs[1].attribute.musicGenre.length; i++) {
          json.style += this.tabs[1].attribute.musicGenre[i].name + ",";
        }
        
        json.style += this.tabs[1].attribute.musicStyle;
        if(json.style.length>120){
          this.snackbar = true;
          this.text2 = "歌曲风格累计长度不能超过120个字符";
          this.loading = false;
          return;
        }
        json.title = this.tabs[1].attribute.songName;
        json.custom = true;
      }

      json.style = this.trimLastComma(json.style.replace(/，/g, ','));
      json.style = this.translateMusicGenre(json.style);
      
      this.$axios.post('/music/api/create/ace', json)
        .then(response => {
          if (response.status === 200) {
            const responseData = response.data;

            // 确认返回的数据类型是否符合预期
            if (responseData.type === 'ok' && responseData.code === 2000) {
              this.snackbar = true;
              this.text2 = '任务提交成功，扣除积分：10';

              //初始化表单
              this.tabs[0].attribute.lyricsDescription = '';
              this.tabs[1].attribute.lyric ='';
              this.tabs[1].attribute.songName = '';
              this.tabs[1].attribute.musicStyle = '';
              this.tabs[1].attribute.musicGenre = [];
              this.tabs[0].attribute.absoluteMusic = 0;
              this.tabs[1].attribute.absoluteMusic = 0;
              //刷新列表
              this.getSongList();
              this.timerConditions = true;
              this.startCountdown();
              if (this.windowWidth < 1280) {
                this.drawerVisible = true;
              }

            } else {
              this.loading = false;
              this.snackbar = true;
              this.text2 = '请求失败:' + response.data.message;
              this.loading = false;
            }
          } else {
            this.loading = false;
            this.snackbar = true;
            this.text2 = '请求失败:' + response.data.message;
            this.loading = false;
          }
        })
        .catch(error => {
          if(error.response.data.message=="请先登录"){
            this.showLoginDialog = true;
          }
          this.loading = false;
          this.snackbar = true;
          this.text2 = error.response.data.message;
          this.loading = false;
        });


      setTimeout(() => (
        this.loading = false
      ), 2000)
    },
    mounted() {
      window.addEventListener('resize', this.updateWindowDimensions);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }
  },
}
</script>

<style scoped>
.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.ellipsis {
  display: inline-block;
  /* 使 span 表现为块级元素 */
  white-space: nowrap;
  /* 不换行 */
  overflow: hidden;
  /* 隐藏超出部分 */
  text-overflow: ellipsis;
  /* 用省略号代替 */
  max-width: 100px;
  /* 设置最大宽度 */
}

.ellipsis-pc {
  display: inline-block;
  /* 使 span 表现为块级元素 */
  white-space: nowrap;
  /* 不换行 */
  overflow: hidden;
  /* 隐藏超出部分 */
  text-overflow: ellipsis;
  /* 用省略号代替 */
  max-width: 200px;
  /* 设置最大宽度 */
}

.red {
  color: rgb(228, 169, 158);
  /* 设置文本颜色为红色 */
}


.grey {
  color: rgb(255, 255, 255);
  /* 设置文本颜色为白色 */
}

.cardTitle{
  background-color: rgba(0, 0, 0, 1);
  padding: 4px 8px;
  opacity: 1; 
}

.cardActions{
  background-color: rgba(0, 0, 0, 1);
  opacity: 1; 
}


.cardPlay{
  background-color: rgba(0, 0, 0, 1);
  border-radius: 999px; 
  padding: 4px 8px;
  opacity: 1; 
}
</style>