<template>
  <v-app>
    <v-main>
      <!-- 积分记录 -->
      <template>
        <v-dialog v-model="dialog" width="auto">
          <v-card>
            <template v-slot:actions>
              <v-btn class="ms-auto" text="关闭" @click="dialog = false"></v-btn>
            </template>
            <v-card title="积分记录" flat>
              <v-divider></v-divider>

              <v-data-table :headers="headers" :items="desserts" :search="search" :items-per-page="5"
                :items-per-page-options="windowWidth<768?[5]:[5,10]" :items-per-page-text="itemsPerPageText" @update:page="fetchData"></v-data-table>
            </v-card>

          </v-card>
        </v-dialog>
      </template>


      <!-- 消息提示 -->
      <v-snackbar :timeout="2000" v-model="snackbar" color="white" vertical>
        <div class="text-subtitle-1 pb-2">提示</div>
        <p>{{ text }}</p>

        <template v-slot:actions>
          <v-btn color="pink" variant="text" @click="snackbar = false">
            关闭
          </v-btn>
        </template>
      </v-snackbar>
      <login-dialog :open="showLoginDialog" @loginSuccess="handleLoginSuccess" @cancellation="handleCancellation" />
      <v-container>
        <v-row justify="center">
          <v-col cols="12" sm="8" md="6">
            <v-card class="pa-4 elevation-12 rounded-lg shadow-lg">
              <div v-if="windowWidth > 768 || windowWidth < 768 && isLogin === false">
                <v-img src="../assets/123.gif" height="200" cover class="rounded-t-lg"></v-img>
              </div>

              <v-card-title class="text-h5 text-center mt-4">
                <span>我的信息</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6">
                    <div class="d-flex align-center">
                      <span class="mr-2">用户ID:</span>
                      <span>{{ user.id }}</span>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <div v-if="!updateUsername" class="d-flex align-center">
                      <span class="mr-2">用户名:</span>
                      <span>{{ user.nickname }}</span>
                    </div>
                    <v-text-field v-else v-model="updateUsernameText" label="用户名" variant="outlined" outlined>
                      <template #append>
                        <v-btn variant="text" icon @click="cancelEdit">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-btn v-if="updateUsername" variant="text" @click="saveUsername">
                          保存

                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>



                  <v-col cols="12" sm="6">
                    <div class="d-flex align-center">
                      <span class="mr-2">积分:</span>
                      <span>{{ user.integral }}</span>
                    </div>
                  </v-col>

                  <v-col v-if="isLogin" cols="12" sm="6">
                    <div @click="$router.push('member')" class="d-flex align-center">
                      <span class="mr-2">会员等级:</span>
                      <span :style="{ color: membershipColor }">{{ membershipText }}</span>
                      <v-icon :icon="membershipIcon" :color="membershipColor"></v-icon>
                    </div>
                  </v-col>

                  <v-col v-if="isLogin" cols="12" sm="6">
                    <div class="d-flex align-center">
                      <span class="mr-2">会员到期时间:</span>
                      <span>{{ user.expirationTime }}</span>
                    </div>
                  </v-col>


                </v-row>
                <v-divider class="mb-10 mt-4"></v-divider>
                <v-row v-if="isLogin">
                  <v-text-field label="兑换码" variant="outlined" v-model="code" outlined>
                    <template #append>
                      <v-btn variant="text" @click="redeemCode">
                        兑换
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-row>


                <v-row v-if="isLogin" justify="center">
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="$router.push('mywork');" variant="outlined">
                      <v-icon>mdi-briefcase-outline</v-icon><span v-if="windowWidth > 768">我的</span>作品
                    </v-btn>
                    <v-btn @click="dialogLoging" variant="outlined">
                      <v-icon>mdi-order-alphabetical-ascending</v-icon>积分记录
                    </v-btn>
                    <v-btn v-if="!updateUsername" variant="outlined" @click="editUsername">
                      <span v-if="windowWidth > 768">更改用户名</span><span v-else>改名</span>
                    </v-btn>

                  </v-card-actions>
                </v-row>


                <v-row class="mt-5" justify="center">
                  <v-btn v-if="isLogin" variant="outlined" @click="logout" size="large" class="mr-2">
                    退出登录
                  </v-btn>
                  <v-btn v-else variant="outlined" @click="toggleLoginDialog" size="large">
                    登录/注册
                  </v-btn>
                </v-row>
              </v-card-text>


            </v-card>
          </v-col>
        </v-row>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import LoginDialog from '../components/LocalDialog.vue';

export default {
  name: 'PersonalCenter',
  components: {
    LoginDialog,
  },
  data() {
    return {
      itemsPerPageText: '页条数',
      search: '',
      headers: [
        {
          align: 'start',
          key: 'id',
          sortable: false,
          title: '序号',
        },
        { key: 'integral', title: '积分' },
        { key: 'type', title: '类型' },
        { key: 'balance', title: '余额' },
        { key: 'remark', title: '备注' },
        { key: 'createdAt', title: '时间' },
      ],
      desserts: [],
      showLoginDialog: false,
      dialog: false,
      isLogin: false,
      snackbar: false,
      windowWidth: window.innerWidth,
      text: '',
      code: '',
      updateUsernameText: '',
      user: {
        id: 0,
        nickname: '未知',
        integral: 0,
        avatarUrl: "https://cdn1.qiaoyanai.cn/image_a719e875-f933-4a66-b11f-a1ff177b8b4a.png",
        membershipLevel: 0,
        expirationTime: ''
      },
      updateUsername: false,
    };
  },
  created() {
    this.$axios.get('/user/select').then(response => {
      if (response.data.code === 2000) {
        this.isLogin = true;
        const userdata = response.data.data;
        this.user.id = userdata.id;
        this.user.nickname = userdata.nickname;
        this.user.integral = userdata.integral;
        this.user.avatarUrl = userdata.avatarUrl;
        this.user.membershipLevel = userdata.membershipLevel;
        this.user.expirationTime = this.formatDate(userdata.expirationTime);
      }
    }).catch(error => {
      if (error.response.data.message === '请先登录') {

      }
    });
  },
  mounted() {
    window.addEventListener('resize', this.updateWindowDimensions);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  },
  methods: {
    fetchData(page) {
      console.log(page)
    },
    dialogLoging() {
      this.$axios.get('/user/integralRecords').then(response => {
        if (response.data.code === 2000) {
          let pointsList = response.data.data;
          for (let i = 0; i < pointsList.length; i++) {
            pointsList[i].id = i + 1;
            if (pointsList[i].type === 1) {
              pointsList[i].type = '增加';
            } else {
              pointsList[i].integral = pointsList[i].integral;
              pointsList[i].type = '减少';
            }
            pointsList[i].createdAt = this.toFormatDateTime(pointsList[i].createdAt);
          }
          this.desserts = pointsList;
          this.dialog = true;
        }
      }).catch(error => {
        this.text = error.response.data.message;
        this.snackbar = true;
      });
      this.dialog = true;
    },
    updateWindowDimensions() {
      this.windowWidth = window.innerWidth;
    },
    toggleLoginDialog() {
      this.showLoginDialog = !this.showLoginDialog;
    },
    formatDate(dateString) {
      const date = new Date(dateString);

      function padZero(num) {
        return num.toString().padStart(2, '0');
      }

      const year = date.getFullYear();
      const month = padZero(date.getMonth() + 1);
      const day = padZero(date.getDate());
      return `${year}-${month}-${day}`;
    },
    handleLoginSuccess(data) {
      // Handle login success logic here
    },
    handleCancellation(data) {
      this.showLoginDialog = false;
    },
    editUsername() {
      this.updateUsernameText = this.user.nickname;
      this.updateUsername = true;
    },
    toFormatDateTime(isoDateString) {
      let result = isoDateString.replace(".000+00:00", "");;

      return result.replace("T", ' ');;
    },
    saveUsername() {
      if (this.updateUsernameText === '') {
        this.text = '请输入用户名';
        this.snackbar = true;
        return;
      }
      if (this.updateUsernameText.length > 10) {
        this.text = '用户名不能超过10个字符';
        this.snackbar = true;
        return;
      }

      if (this.updateUsernameText === this.user.nickname) {
        this.updateUsername = false;
        return;
      }
      this.updateUsernameText = this.updateUsernameText.trim();
      this.$axios.post('/user/updateUserName', {
        id: this.user.id,
        nickname: this.updateUsernameText
      }).then(response => {
        if (response.data.code === 2000) {
          this.user.nickname = this.updateUsernameText;
          this.snackbar = true;
          this.text = '用户名修改成功';
        } else {
          this.snackbar = true;
          this.text = response.data.message;
        }
      }).catch(error => {
        this.text = error.response.data.message;
        this.snackbar = true;
      })
      this.updateUsername = false;
    },
    cancelEdit() {
      this.updateUsernameText = '';
      this.updateUsername = false;
    },
    redeemCode() {
      if (this.code === '') {
        this.text = '请输入兑换码';
        this.snackbar = true;
        return;
      }

      this.snackbar = true;
      this.text = '兑换码无效';
    },
    logout() {
      localStorage.removeItem('Authorization');
      this.text = '退出登录';
      this.snackbar = true;
      location.reload();
    }
  },
  computed: {
    membershipColor() {
      switch (this.user.membershipLevel) {
        case 0:
          return 'black';
        case 1:
          return '#FF7F24';
        case 2:
          return '#FF7F24';
        case 3:
          return '#FF7F24';
        case 4:
          return '#FF7F24';
        case 5:
          return '#FF7F24'
        case 6:
          return '#FF7F24'
        default:
          return 'black';
      }
    },
    membershipIcon() {
      switch (this.user.membershipLevel) {
        case 0:
          return 'mdi-account';
        case 1:
          return 'mdi-crown';
        case 2:
          return 'mdi-crown';
        case 3:
          return 'mdi-crown';
        case 4:
          return 'mdi-crown';
        case 5:
          return 'mdi-crown';
        case 6:
          return 'mdi-crown';
        default:
          return 'mdi-account';
      }
    },
    membershipText() {
      switch (this.user.membershipLevel) {
        case 0:
          return '普通用户';
        case 1:
          return '体验会员';
        case 2:
          return '高级会员';
        case 3:
          return '高级会员';
        case 4:
          return '高级会员';
        case 5:
          return '超级会员';
        case 6:
          return '超级会员plus';
        default:
          return '普通用户';
      }
    },
    memberBtn() {
      switch (this.user.membershipLevel) {
        case 0:
          return '会员订阅';
        default:
          return '续订会员';
      }
    }
  }
};
</script>

<style scoped>
/* 头像样式 */
.v-avatar img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

/* 卡片样式 */
.v-card {
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* 标题样式 */
.v-card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

/* 内容区域样式 */
.v-card-text {
  padding: 1rem;
}

/* 按钮样式 */
.v-btn {
  font-weight: bold;
  transition: background-color 0.3s ease;
  border-radius: 8px;
}

/* 按钮悬停效果 */
.v-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* 输入框样式 */
.v-text-field {
  margin-bottom: 1rem;
}

/* 副标题样式 */
.text-subtitle-1 {
  font-size: 1.2rem;
  font-weight: 500;
}

.red-text {
  color: red;
}
</style>
