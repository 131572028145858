<template>
    <div class="ma-1">
        <!-- 消息提示 -->
        <v-snackbar :timeout="2000" v-model="snackbar" color="white" vertical>
            <div class="text-subtitle-1 pb-2">提示</div>
            <p>{{ text }}</p>
            <template v-slot:actions>
                <v-btn color="pink" variant="text" @click="snackbar = false">
                    关闭
                </v-btn>
            </template>
        </v-snackbar>
        <v-container fluid>
            <v-row justify="center">
                <v-col cols="12" sm="8" md="6">
                    <v-card class="pa-4">
                        <v-row align="center" justify="center">
                            <v-col cols="12" sm="6" md="4" class="d-flex justify-center">
                                <v-icon color="success" class="mb-1" v-if="payOk===1">mdi-clock-outline</v-icon>支付结果
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-divider class="my-4"></v-divider>
                            <div v-if="remainingTime >= 0 && payOk===0">
                                <span class="ml-5 mb-1 mr-1"><v-icon class="mb-1">mdi-spin
                                        mdi-loading</v-icon>正在等待返回结果，请稍后</span>
                                <span>（{{ remainingTime }} 秒）</span>
                            </div>

                            <div v-if="remainingTime === 0 && payOk===1" class="d-flex justify-center">
                                <span class="ml-5 mb-1 mr-1"><v-icon class="mb-1" color="success"
                                        icon="mdi-check-circle-outline" size="60"></v-icon>支付成功！感谢您的支持！</span>
                            </div>

                            <div v-if="remainingTime > 0 && payOk===-1" class="d-flex justify-center">
                                <span class="ml-5 mb-1 mr-1"><v-icon class="mb-1" color="error"
                                        icon="mdi-cancel" size="50"></v-icon>订单不存在！</span>
                            </div>
                            <v-divider></v-divider>

                            <div v-if="remainingTime === 0 && payOk===1" class="pa-4 text-end">
                                <v-btn class="text-none" color="light-blue" min-width="92" variant="text" rounded
                                    @click="this.$router.push('/')">
                                    返回首页
                                </v-btn>
                            </div>

                            <div v-if="remainingTime > 0 && payOk===0" class="pa-4 text-end">
                                <v-btn class="text-none" color="light-blue" min-width="92" variant="text" rounded
                                    @click="dialog = false">
                                    30秒内未成功返回结果，请重新刷新页面。
                                </v-btn>
                            </div>
                            
                            <div v-if="remainingTime <= 0 && payOk===0 || remainingTime > 0 && payOk===-1" class="pa-4 text-end">
                                <v-btn class="text-none" color="light-blue" min-width="92" variant="text" rounded
                                    @click="dialog = false">
                                    联系客服
                                </v-btn>
                            </div>


                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'member',
    data() {
        return {
            taskId: '',
            remainingTime: 28, // 5 分钟
            countdownInterval: null,
            payOk: 0
        }
    },
    created() {
        if (this.$route.query.taskId) {
            this.startCountdown(this.$route.query.taskId)
        } else {
            this.$router.push('/')
        }
    },
    beforeDestroy() {
        clearInterval(this.countdownInterval);
    },
    methods: {
        startCountdown(requestId) {
            this.countdownInterval = setInterval(() => {
                if (this.remainingTime > 0) {
                    this.remainingTime--;
                    if (this.remainingTime % 3 === 0) {
                        this.$axios.get('/pay/resultTaskId?taskId=' + requestId).then(response => {
                            this.payOk = response.data.data
                            console.log(response)
                            console.log('/pay/resultTaskId?taskId=' + requestId)
                            if (response.data.data === 1) {
                                this.remainingTime = 0
                                clearInterval(this.countdownInterval);
                            }

                            if(response.data.data === -1){
                                this.remainingTime = 28
                                clearInterval(this.countdownInterval);
                            }
                        })
                    }
                } else {
                    clearInterval(this.countdownInterval);
                }
            }, 1000);
        },
    }
}
</script>