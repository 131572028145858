<template>
  <div class="ma-5">


    <v-row>
      <v-col>
        <v-card class="mx-auto" color="surface-variant">
          <v-card-text>
            <h1 v-if="windowWidth >= 739" class="text-left">🔥快速开始：输入你的想法，立即生成歌曲！</h1>
            <h3 v-else class="text-left">🔥快速开始：输入你的想法，立即生成歌曲！</h3>
            <v-text-field :loading="loading" append-inner-icon="mdi-music" density="compact"
              label="例：一首关于自由的歌曲，让人感受到无拘无束" variant="solo" hide-details single-line @click:append-inner="onClick"
              @keyup.enter="onClick"
              class="mb-3" v-model="input"></v-text-field>
            <div class="text-right ma-0">
              <span @click="changeTheCopy">换一组文案</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- 选择列表 -->
    <v-row>
      <v-col cols="12" sm="4" v-for="(button, index) in button" :key="index">
        <v-btn block :color="index === 1 ? 'primary' : 'secondary'" dark class="ml-1 mr-1" height="50px"
          @click="templateText(button.text)"><span class="bt-text">{{ button.text }}</span></v-btn>
      </v-col>
    </v-row>

    <!-- 潮流榜单 -->
    <div class="container">
      <span class="music-type">歌曲热榜</span>
      <span class="show-more" @click="spkiList('歌曲热榜',musicCards[0].image,'fiery')">查看更多</span>
    </div>
    <v-row>
      <v-col cols="12" sm="4" v-for="(card, index) in musicCards" :key="index">
        <v-card color="#616161" :image="card.image" @click="">
          <div>
            <div>
              <div @click="spkiPlay(card,musicCards)">
                <v-card-title class="text-h5 cardTitle mb-3">
                  <span>{{ truncateSubtitle(card.title) }}</span>
                </v-card-title>
                <v-card-subtitle style="opacity: 1;"><span class="cardTitle">{{ truncateSubtitle(translateMusicGenreToChinese(card.subtitle)) }}</span></v-card-subtitle>
                <v-card-actions>
                  <v-btn @click.stop="playAudio(index, card, card.audio)" class="ms-2 mt-1 mb-1 cardPlay" :icon=card.icon variant="text"></v-btn>
                </v-card-actions>
              </div>
              <!-- 功能按钮区 -->
              <v-card-actions class="cardActions">
                <v-btn @click="likeMusic(1, index)" :class="{'red': card.liked, 'grey': !card.liked}">
                  <v-icon>mdi-thumb-up</v-icon>
                  <!-- {{ formatNumber(card.likes) }} -->
                </v-btn>
                <v-btn v-if="(windowWidth<1000 && windowWidth<600) || (windowWidth>1000)"><v-icon class="mb-1">mdi-headphones</v-icon>
                  {{ formatNumber(card.plays) }}
                </v-btn>
                <v-btn v-if="(windowWidth<740 && windowWidth<600) || (windowWidth>740)" @click="copyToClipboard(card)"><v-icon class="mb-1">mdi-share</v-icon>
                  <!-- {{ formatNumber(card.forwards) }} -->
                </v-btn>
          
                <!-- 卡片功能菜单 -->
                <v-menu :location="location">
                  <template v-slot:activator="{ props }">
                    <v-btn>
                      <v-icon class="mb-1" v-bind="props">mdi-menu</v-icon></v-btn>
                  </template>

                  <v-list>
                    <v-list-item v-for="(item, index) in cardMenu" :key="index">
                      <v-list-item-title>
                        <v-btn @click.stop="task(item.id, card)">
                          <v-icon>{{ item.icon }}</v-icon>
                          {{ item.title }}
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

              </v-card-actions>
            </div>

          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- 最新音乐 -->
    <div class="container">
      <span class="music-type">最新歌曲</span>
      <span class="show-more" @click="spkiList('最新歌曲',musicCardsNew[0].image,'new')">查看更多</span>
    </div>
    <v-row>
      <v-col cols="12" sm="3" v-for="(card, index) in musicCardsNew" :key="index">
        <v-card color="#616161" :image="card.image" @click="">
          <div>
            <div>
              <div @click="spkiPlay(card,musicCardsNew)">
                <!-- 音乐卡片标题 -->
                <v-card-title class="text-h5 cardTitle mb-3">
                  <span>{{ truncateSubtitle(card.title) }}</span>
                </v-card-title>
                <!-- 音乐卡片内容 -->
                <v-card-subtitle style="opacity: 1;"><span class="cardTitle">{{ truncateSubtitle(translateMusicGenreToChinese(card.subtitle)) }}</span></v-card-subtitle>
                
                <!-- 音乐卡片播放按钮 -->
                <v-card-actions>
                  <v-btn  @click.stop="playAudio(index, card, card.audio)" class="ms-2 mt-1 mb-1 cardPlay" :icon=card.icon variant="text"></v-btn>
                </v-card-actions>
              </div>
              <!-- 功能按钮区 -->
              <v-card-actions class="cardActions">
                <v-btn @click="likeMusic(2, index)" :class="{'red': card.liked, 'grey': !card.liked}">
                  <v-icon>mdi-thumb-up</v-icon>
                  <!-- {{ formatNumber(card.likes) }} -->
                </v-btn>
                <v-btn v-if="(windowWidth<1200 && windowWidth<600) || (windowWidth>1200)"><v-icon class="mb-1">mdi-headphones</v-icon>
                  {{ formatNumber(card.plays) }}
                </v-btn>
                <v-btn v-if="(windowWidth<900 && windowWidth<600) || (windowWidth>900)" @click="copyToClipboard(card)"><v-icon class="mb-1">mdi-share</v-icon>
                  <!-- {{ formatNumber(card.forwards) }} -->
                </v-btn>
   
                <!-- 卡片功能菜单 -->
                <v-menu :location="location">
                  <template v-slot:activator="{ props }">
                    <v-btn>
                      <v-icon class="mb-1" v-bind="props">mdi-menu</v-icon></v-btn>
                  </template>

                  <v-list>
                    <v-list-item v-for="(item, index) in cardMenu" :key="index">
                      <v-list-item-title>
                        <v-btn @click.stop="task(item.id, card)">
                          <v-icon>{{ item.icon }}</v-icon>
                          {{ item.title }}
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>


              </v-card-actions>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- 热门分类 -->
    <div class="container">
      <span class="music-type">歌曲分类</span>
    </div>
    <v-row>
      <v-col cols="12" sm="3" v-for="(card, index) in cards" :key="index">
        <v-card @click="spkiList(card.title,card.src,card.type)">
          <v-img :src="card.src" class="align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="150px"
            cover>
            <v-card-title class="text-white" v-text="card.title"></v-card-title>
          </v-img>
        </v-card>
      </v-col>
    </v-row>


    <!-- 消息提示 -->
    <v-snackbar
          :timeout="2000"
          v-model="snackbar"
          color="white"
          vertical
        >
        <div class="text-subtitle-1 pb-2">提示</div>
          <p>{{ text }}</p>

          <template v-slot:actions>
            <v-btn
              color="pink"
              variant="text"
              @click="snackbar = false"
            >
              关闭
            </v-btn>
          </template>
        </v-snackbar>
  </div>


</template>

<script>

export default {
  name: 'Home',

  data: () => ({
    windowWidth: window.innerWidth,
    // 提示框
    snackbar: false,
    text: '',
    //首页输入框加载动画
    loaded: false,
    loading: false,
    // 首页输入框
    input: '',
    button: [
      { text: '☀️一首充满希望的歌曲，像阳光洒在心上' },
      { text: '🌊一首关于海洋的歌曲，让人想起海边的风景' },
      { text: '✨一首关于田园爱情的歌，就像夏天吹过的风' },
    ],
    buttons: [
      { text: '🌟一首富有感染力的歌曲，有雨滴落下的声音' },
      { text: '📝创作一首下雨天听的歌曲' },
      { text: '✨一首关于田园爱情的歌，就像夏天吹过的风' },
      { text: '☀️一首充满希望的歌曲，像阳光洒在心上' },
      { text: '💪一首关于梦想的歌曲，让人充满力量' },
      { text: '🔥一首温暖的歌曲，像冬日里的暖阳' },
      { text: '❤️一首关于友情的歌曲，让人感到温馨' },
      { text: '✈️一首关于旅行的歌曲，让人向往远方' },
      { text: '🕊️一首关于自由的歌曲，让人感受到无拘无束' },
      { text: '🌊一首关于海洋的歌曲，让人想起海边的风景' },
      { text: '🌌一首关于夜晚的歌曲，让人沉醉于星空' },
      { text: '🍬一首关于爱情的歌曲，让人感到甜蜜' },
      { text: '🍂一首关于回忆的歌曲，让人怀念过去' },
      { text: '🔮一首关于未来的歌曲，让人充满期待' },
      { text: '🍁一首关于季节的歌曲，让人感受四季之美' },
      { text: '🏙️一首关于城市的歌曲，让人想起繁华都市' },
      { text: '🌾一首关于乡村的歌曲，让人想起宁静乡间' },
      { text: '🌱一首关于自然的歌曲，让人感受大自然的魅力' },
      { text: '🏆一首关于梦想的歌曲，让人勇往直前' },
      { text: '🌱一首关于青春的歌曲，让人回味年轻时光' },
      { text: '🏠一首关于家庭的歌曲，让人感到温暖与幸福' }
    ],
    cardMenu: [
      { id: 1, title: '下载音频', icon: 'mdi-download' },
      { id: 2, title: '生成类似', icon: 'mdi-refresh' },
      // { id: 3, title: '延长音频', icon: 'mdi-playlist-plus' },
      // { id: 4, title: '关闭背景', icon: 'mdi-image-off' },
      { id: 5, title: '音乐分享', icon: 'mdi-share' },
    ],
    locations: [
      'top',
      'bottom',
      'start',
      'end',
      'center',
    ],
    location: 'end',
    musicCards: [
      { id: 0,
              typeId: 1,
              title: '加载中',
              subtitle: '加载中',
              image: '',
              audio: '',
              color: '#616161',
              icon: 'mdi-play',
              likes: 0,
              plays: 0,
              forwards: 0,
              liked: false
            },{ id: 0,
              typeId: 1,
              title: '加载中',
              subtitle: '加载中',
              image: '',
              audio: '',
              color: '#616161',
              icon: 'mdi-play',
              likes: 0,
              plays: 0,
              forwards: 0,
              liked: false
            },{ id: 0,
              typeId: 1,
              title: '加载中',
              subtitle: '加载中',
              image: '',
              audio: '',
              color: '#616161',
              icon: 'mdi-play',
              likes: 0,
              plays: 0,
              forwards: 0,
              liked: false
            },
    ],
    musicCardsNew: [],
    cards: [
      { title: '流行音乐', src: 'https://cdn2.suno.ai/image_83e17373-393d-4a56-9f9d-a71305454882.jpeg',type:'pop' },
      { title: '摇滚音乐', src: 'https://cdn2.suno.ai/image_81761072-cd5b-47fa-a7a7-cfbf63eaa41a.jpeg', type:'Rock' },
      { title: '电子音乐', src: 'https://cdn2.suno.ai/image_e0ff1ad3-bb70-4f7f-8e16-c650607ae230.jpeg',type:'Electronic' },
      { title: '古典音乐', src: 'https://cdn2.suno.ai/image_a131fb44-1f7a-4981-b95a-add6af6bad19.jpeg',type:'classical' },
      { title: '嘻哈说唱', src: 'https://cdn2.suno.ai/image_81761072-cd5b-47fa-a7a7-cfbf63eaa41a.jpeg',type:'rap' },
      { title: '重金属歌曲', src: 'https://cdn2.suno.ai/image_3ee92dec-bfe4-4092-8aa5-12e47b5e599a.jpeg', type:'Metal' },
      { title: '男声专区', src: 'https://cdn2.suno.ai/image_ad95dfc2-447d-4e99-adad-a55f9a84239e.jpeg',type:'Male Vocal' },
      { title: '女声专区', src: 'https://cdn2.suno.ai/image_0dd0f28d-56e1-467f-a01f-00f7a22a163a.jpeg',type:'Female Vocal' },
    ]
  }),
  created() {
    this.changeTheCopy();

    this.$axios.get('/music/getPopularMusic?page=3').then(response => {
        const songs = response.data.data;
        let arr = []
        for(let i = 0; i < songs.length; i++){
          const data =  {
              id: 0,
              typeId: 1,
              title: '',
              subtitle: '',
              image: '',
              audio: '',
              color: '#616161',
              icon: 'mdi-play',
              likes: 0,
              plays: 0,
              forwards: 0,
              liked: false
      }

          data.id = songs[i].native_id;
          data.title = songs[i].title;
          data.subtitle = songs[i].tags;
          data.image = songs[i].native_image_url;
          data.audio = songs[i].native_audio_url;
          data.plays = songs[i].play_count;
          data.forwards = songs[i].forward_count;
          data.likes = songs[i].likeCount;
          arr.push(data);
          
        }
        this.musicCards= arr;
        if (this.$globalObject.typeId === 1 && !this.$globalAudio.paused) {
      for(let i = 0; i < this.musicCards.length; i++){
        if(this.musicCards[i].id === this.$globalObject.id){
          this.musicCards[i].icon = 'mdi-pause';
        }
      }
    }
    })

    this.$axios.get('/music/getNewMusic?page=8').then(response => {
        const songs = response.data.data;
        for(let i = 0; i < songs.length; i++){
          if(this.musicCardsNew.length>7){
            break;
          }
          const data =  {
              id: 0,
              typeId: 2,
              title: '',
              subtitle: '',
              image: '',
              audio: '',
              color: '#616161',
              icon: 'mdi-play',
              likes: 0,
              plays: 0,
              forwards: 0,
              liked: false
      }

          data.id = songs[i].native_id;
          data.title = songs[i].title;
          data.subtitle = songs[i].tags;
          data.image = songs[i].native_image_url;
          data.audio = songs[i].native_audio_url;
          data.plays = songs[i].play_count;
          data.forwards = songs[i].forward_count;
          data.likes = songs[i].likeCount;
          this.musicCardsNew.push(data);
        }
        if (this.$globalObject.typeId === 2 && !this.$globalAudio.paused) {
          for(let i = 0; i < this.musicCardsNew.length; i++){
            if(this.musicCardsNew[i].id === this.$globalObject.id){
              this.musicCardsNew[i].icon = 'mdi-pause';
            }
      }
    }
    })

   

   
 

  },
  methods: {
    
    translateMusicGenreToChinese(str) {
      const parts = str.split(',');
      let newStr = '';
      for(let i = 0; i < parts.length; i++){
        if(this.$genreMapReversed[parts[i]]){
          newStr+=this.$genreMapReversed[parts[i]]+','
        }else{
          newStr+=parts[i]+','
        }
        
      }
      if (newStr.endsWith(',')) { 
        return newStr.slice(0, -1); // 返回去掉最后一个字符的字符串
      }
      return newStr;
    },
    
    likeMusic(tpye,index){
      //点赞功能
      if(tpye===1){
        if(!this.musicCards[index].liked){
        this.musicCards[index].likes++;
        this.musicCards[index].liked=!this.musicCards[index].liked;
      }else{
        this.musicCards[index].likes--;
        this.musicCards[index].liked=!this.musicCards[index].liked;
      }
      
    }else{
        if(!this.musicCardsNew[index].liked){
        this.musicCardsNew[index].likes++;
        this.musicCardsNew[index].liked=!this.musicCardsNew[index].liked;
      }else{
        this.musicCardsNew[index].likes--;
        this.musicCardsNew[index].liked=!this.musicCardsNew[index].liked;
      }
    }
    },
    //跳转播放页面
    spkiPlay(card,cards) {
      let cardsId = card.id;
      for(let i = 0; i < cards.length; i++){
        cardsId = cardsId + ',' + cards[i].id;
      }
      let cardId = this.toBase64(card.id);
      cardsId = this.toBase64(cardsId);
      this.$router.push({
      path: '/musicPlayer',
      query: { id:  cardId,ids : cardsId }
    });
    },
    //跳转列表页面
    spkiList(name,img,keyword) {
      this.$router.push({
      path: '/musicList',
      query: { type:   this.toBase64(name),img:  this.toBase64(img),keyword: this.toBase64(keyword) }
    });
    },
    truncateSubtitle(subtitle) {
      if (subtitle.length > 20) {
        return subtitle.slice(0, 20) + '...';
      }
      return subtitle;
    },
    toBase64(str) {
    // 使用 TextEncoder 将字符串转换为 ArrayBuffer
    const buffer = new TextEncoder().encode(str);
    // 使用 btoa 将 ArrayBuffer 转换为 Base64 字符串
    return btoa(String.fromCharCode(...new Uint8Array(buffer)));
},
    //格式化数字显示
    formatNumber(number) {
            if (number >= 10000) {
                return (number / 10000).toFixed(1) + 'w';
            } 
            else if (number >= 1000) {
                return (number / 1000).toFixed(1) + 'k';
            }else {
                return number.toString();
            }
        },
    task(id, card) {
      if (id === 5) {
        this.copyToClipboard(card);
        return;
      }

      //关闭背景
      if (id === 4) {
        for(let i = 0; i < this.musicCards.length; i++){
          if(this.musicCards[i].id === card.id){
            this.musicCards[i].image = '';
            return;
          }
        }

        for(let i = 0; i < this.musicCardsNew.length; i++){
          if(this.musicCardsNew[i].id === card.id){
            this.musicCardsNew[i].image = '';
            return;
          }
        }
      }

      // 生成类似
      if(id===2){
            this.generateSimilar(card.title,card.subtitle);
        }
       

      //下载音频
      if (id === 1) {
        this.snackbar = true;
        this.text = '已提交下载任务，请稍后~';
        if (!card.audio) {
          this.snackbar = true;
          this.text = '该音频未提供下载链接！';
          return;
        }

        fetch(card.audio)
        .then(response => {
          if (!response.ok) {
            this.snackbar = true;
            this.text = '网络请求失败';
            throw new Error('网络请求失败');
          }
          return response.blob();
        })
        .then(blob => {
          // 创建 Blob 对象
          const url = window.URL.createObjectURL(blob);

          // 创建临时链接
          const a = document.createElement('a');
          a.href = url;
          a.download = card.title+'.mp3'; // 设置下载文件名

          // 触发点击事件
          document.body.appendChild(a);
          a.click();

          // 清除临时链接
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        })
        .catch(error => {
        });
      }


     
    },
    updateWindowDimensions() {
      this.windowWidth = window.innerWidth;
    },
    onClick() {
      this.loading = true;
      if(!this.input){
        this.input = "一首关于自由的歌曲，无拘无束"
      }

      this.$router.push({
      path: '/create',
      query: { keywords:  this.toBase64(this.input) }
    });
      this.loading = false;
    },
    templateText(text) {
      this.$router.push({
      path: '/create',
      query: { keywords: this.toBase64(text) }
    });
    },
    generateSimilar(name,style) {
      this.$router.push({
      path: '/create',
      query: { name: this.toBase64(name),style:this.toBase64(style) }
    });
    },
    changeTheCopy() {
      // 创建一个数组副本
      let buttonsCopy = [...this.buttons];

      // 随机选择三个元素
      let selectedButtons = [];
      for (let i = 0; i < 3; i++) {
        let randomIndex = Math.floor(Math.random() * buttonsCopy.length);
        selectedButtons.push(buttonsCopy[randomIndex]);
        buttonsCopy.splice(randomIndex, 1); // 移除已选中的元素
      }
      this.button = selectedButtons;
    },
    playAudio(index, card, src) {
      const globalAudio = this.$globalAudio;

      //检测是否点击同一曲目且是否在播放中
      if (this.$globalObject.typeId === card.typeId && this.$globalObject.id === card.id && !globalAudio.paused) {
        globalAudio.pause();
        //如果是热歌推荐
        if (this.$globalObject.typeId === 1) {
          for (let i = 0; i < this.musicCards.length; i++) {
            if (this.musicCards[i].id === this.$globalObject.id) {
              this.musicCards[i].icon = 'mdi-play';
              return;
            }
          }
        }


        if (this.$globalObject.typeId === 2) {
          for (let i = 0; i < this.musicCardsNew.length; i++) {
            if (this.musicCardsNew[i].id === this.$globalObject.id) {
              this.musicCardsNew[i].icon = 'mdi-play';
              return;
            }
          }
        }

        return;
      }

      if (this.$globalObject.typeId === card.typeId && this.$globalObject.id === card.id && globalAudio.paused) {
        globalAudio.play().catch(err => {
        });
        //如果是热歌推荐
        if (this.$globalObject.typeId === 1) {
          for (let i = 0; i < this.musicCards.length; i++) {
            if (this.musicCards[i].id === this.$globalObject.id) {
              this.musicCards[i].icon = 'mdi-pause';
              return;
            }
          }
        }


        if (this.$globalObject.typeId === 2) {
          for (let i = 0; i < this.musicCardsNew.length; i++) {
            if (this.musicCardsNew[i].id === this.$globalObject.id) {
              this.musicCardsNew[i].icon = 'mdi-pause';
              return;
            }
          }
        }

        return;
      }


      for (let i = 0; i < this.musicCards.length; i++) {
        //如果是热歌推荐
        if (this.$globalObject.typeId === 1) {
          for (let i = 0; i < this.musicCards.length; i++) {
            if (this.musicCards[i].id === this.$globalObject.id) {
              this.musicCards[i].icon = 'mdi-play';
              break;
            }
          }
        }


        if (this.$globalObject.typeId === 2) {
          for (let i = 0; i < this.musicCardsNew.length; i++) {
            if (this.musicCardsNew[i].id === this.$globalObject.id) {
              this.musicCardsNew[i].icon = 'mdi-play';
              break;
            }
          }
        }
      }

      this.$globalObject.typeId = card.typeId;
      this.$globalObject.id = card.id;
      this.$globalObject.index = index;
      this.$globalObject.audio = src;
      this.$globalObject.image = card.image;
      this.$globalObject.title = card.title;
      this.$globalObject.subtitle = card.subtitle;



      // 停止全局音频
      globalAudio.pause();
      globalAudio.currentTime = 0; // 重置播放位置
      globalAudio.src = ''; // 清空 src，防止自动播放      

      globalAudio.load(); // 重新加载音频
      // 设置新的音频源
      globalAudio.src = src;
      globalAudio.load();

      // 开始播放
      globalAudio.play().catch(err => {
        console.error('播放错误:', err);
      });


      //重置播放列表
      for (let i = 0; i < this.musicCards.length; i++) {
        this.musicCards[i].icon = 'mdi-play';
      }
      for (let i = 0; i < this.musicCardsNew.length; i++) {
        this.musicCardsNew[i].icon = 'mdi-play';
      }

      //首次播放更换图标
      if (this.$globalObject.typeId === 1) {
        for (let i = 0; i < this.musicCards.length; i++) {
          if (this.musicCards[i].id === card.id) {
            this.musicCards[i].icon = 'mdi-pause';
            break;
          }
        }
      }


      if (this.$globalObject.typeId === 2) {
        for (let i = 0; i < this.musicCardsNew.length; i++) {
          if (this.musicCardsNew[i].id === card.id) {
            this.musicCardsNew[i].icon = 'mdi-pause';
            break;
          }
        }
      }

      // 添加事件监听器
      globalAudio.addEventListener('ended', () => {
        if (this.$globalObject.typeId === 1) {
          for (let i = 0; i < this.musicCards.length; i++) {
            if (this.musicCards[i].id === card.id) {
              this.musicCards[i].icon = 'mdi-play';
              break;
            }
          }
        }


        if (this.$globalObject.typeId === 2) {
          for (let i = 0; i < this.musicCardsNew.length; i++) {
            if (this.musicCardsNew[i].id === card.id) {
              this.musicCardsNew[i].icon = 'mdi-play';
              break;
            }
          }
        }
      });
    },
    copyToClipboard(card) {

      let text = '我在sunosong分享了一首歌曲，快来听听吧：'+this.$globalUrl+'?id='+this.toBase64(card.id);
      
      if (navigator.clipboard && navigator.clipboard.writeText) {
        // 使用现代浏览器的 clipboard API
        navigator.clipboard.writeText(text).then(() => {
          this.snackbar = true;
          this.text = '复制成功！快去分享给您的好友吧~';
        }, (err) => {
          console.error('Failed to copy text: ', err);
        });
      } else {
        // 兼容旧版浏览器
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand('copy');
          this.snackbar = true;
          this.text = '复制成功！快去分享给您的好友吧~';
        } catch (err) {
          console.error('Failed to copy text: ', err);
        }
        document.body.removeChild(textarea);
      }
    }

  },
  mounted() {
    window.addEventListener('resize', this.updateWindowDimensions);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
}
</script>

<style scoped>
.text-left {
  margin-bottom: 20px;
}

.v-btn {
  margin-top: 0;
}

.bt-text {
  font-size: 15px;
  white-space: pre-wrap;
  word-break: break-word;
}

.music-type {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
}

.show-more {
  margin-top: 18px;
  color: #000000;
  font-size: 15px;
  text-align: right;
}

.container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.red {
  color: rgb(228, 169, 158); /* 设置文本颜色为红色 */
}


.grey {
  color: rgb(255, 255, 255); /* 设置文本颜色为黑色 */
}

.cardTitle{
  background-color: rgba(0, 0, 0, 1);
  padding: 4px 8px;
  opacity: 1; 
}

.cardActions{
  background-color: rgba(0, 0, 0, 1);
  opacity: 1; 
}

.cardPlay{
  background-color: rgba(0, 0, 0, 1);
  border-radius: 999px; 
  padding: 4px 8px;
  opacity: 1; 
}
</style>