<template>
  <div class="ma-10">
    <pay-dialog v-if="showPayDialog" :open="showPayDialog" :value="value" :num="num" @loginSuccess="handlePaySuccess" @cancellation="handleCancellation" />
    <h1 class="text-h4 font-weight-bold d-flex justify-space-between mb-4 align-center">
      <div class="text-truncate">
        会员订阅
      </div>
    </h1>
    <v-container
      class="mb-6"
    >
      <v-row
        align="start"
        no-gutters
      >
        <v-col
        cols="12" lg="3" sm="3"
          v-for="(item, index) in members" :key="index"
        >
          <v-sheet class="pa-2 ma-2">
            <v-card
              class="mx-auto"
              color="surface-variant"
              max-width="500"
              :title="item.name"
            >
              <p class="ml-3 mb-5 text-h5 text-amber-lighten-2"><del class="text-grey-lighten-1" v-show="item.activity"><span>￥528</span></del>￥<span>{{ item.price }}</span></p>
              <p class="ml-5 mr-5"><v-icon color="#54FF9F">mdi-check</v-icon>您将获得<span class="text-orange-lighten-2">{{ item.integral }}</span>积分(有效期:{{ item.termOfValidity }})</p>
              <p class="ml-5 mr-5"><v-icon color="#54FF9F">mdi-check</v-icon>每首歌<span class="text-orange-lighten-2">≈{{ (item.price/(item.integral/10*2)).toFixed(3) }}</span>元</p>
              <p class="ml-5 mr-5"><v-icon color="#54FF9F">mdi-check</v-icon>积分可生成<span class="text-orange-lighten-2">{{ item.integral/10*2 }}</span>首歌曲</p>
              <p class="ml-5 mr-5"><v-icon color="#54FF9F">mdi-check</v-icon>支持<span class="text-orange-lighten-2">{{ item.integralGenerate }}</span>个任务同时进行</p>
              <p class="ml-5 mr-5"><v-icon color="#54FF9F">mdi-check</v-icon>{{ item.describe1 }}</p>
              <p class="ml-5 mr-5 mb-5"><v-icon color="#54FF9F">mdi-check</v-icon>{{ item.describe2 }}</p>
              <p class="ml-5 mr-5">&nbsp;&nbsp;<span class="countdown" v-show="item.activity">限时特惠：{{ countdown }}</span></p>
              <template v-slot:actions>
                <v-btn
                  append-icon="mdi-chevron-right"
                  text="立即订阅"
                  class="text-none text-white"
                  color="#FFAB00"
                  size="large"
                  variant="flat"
                  block
                  @click="togglePayDialog(item.id,item.price)"
                ></v-btn>
              </template>
            </v-card>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style>
  .golden-btn {
    --v-btn-color: #DAA520 !important;
  }
</style>

<script>
import PayDialog from '../components/PayDialog.vue';

export default {
  name: 'member',
  components: {
    PayDialog,
  },
  data() {
    return {
      showPayDialog: false,
      value: 0,
      num: 0,
      countdown: '00:00:00',
      intervalId: null,
      members: [
        {
          id:2,
          name: '体验会员(天卡)',
          price: '1.0',
          timeUnit:'一天',
          integral:20,
          termOfValidity:'1天',
          integralGenerate:2,
          task:3,
          describe1:'永久歌曲云储存',
          describe2:'普通任务通道',
          activity:false
        },
        {
          id:3,
          name: '高级会员(周卡)',
          price: '9.9',
          timeUnit:'周卡',
          termOfValidity:'7天',
          integral:220,
          integralGenerate:3,
          task:3,
          describe1:'永久歌曲云储存',
          describe2:'会员专享受任务通道',
          activity:false
        },
        {
          id:4,
          name: '高级会员(月卡)',
          price: '19.9',
          timeUnit:'月卡',
          termOfValidity:'30天',
          integral:470,
          integralGenerate:4,
          task:3,
          describe1:'永久歌曲云储存',
          describe2:'会员专享任务通道',
          activity:false
        },
        {
          id:5,
          name: '超级会员(年卡)',
          price: '99',
          timeUnit:'年卡',
          termOfValidity:'365天',
          integral:2680,
          integralGenerate:8,
          task:3,
          describe1:'永久歌曲云储存',
          describe2:'超会专享任务通道',
          activity:false
        },
        {
          id:6,
          name: '超级会员(终身)',
          price: '198',
          timeUnit:'终身',
          termOfValidity:'终身',
          integral:5680,
          integralGenerate:8,
          task:3,
          describe1:'永久歌曲云储存',
          describe2:'超会专享任务通道',
          activity:false
        },
        {
          id:7,
          name: '超级会员PLUS(终身)',
          price: '368',
          timeUnit:'终身',
          termOfValidity:'终身',
          integral:18000,
          integralGenerate:10,
          task:3,
          describe1:'永久歌曲高速云储存',
          describe2:'超会专享任务通道',
          activity:true
        }

      ]
    }
  },
  mounted() {
    this.updateCountdown();
    this.intervalId = setInterval(this.updateCountdown, 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    togglePayDialog(id,price) {
      this.value = id;
      this.num = price;
      this.showPayDialog = !this.showPayDialog;
    },
    handlePaySuccess(data) {
    },
    handleCancellation(data) {
      this.showPayDialog = false;
    },
    updateCountdown() {
      const now = new Date();
      const midnight = new Date(now);
      midnight.setHours(24, 0, 0, 0); // 设置为次日0点
      const timeDifference = midnight.getTime() - now.getTime();

      if (timeDifference <= 0) {
        this.countdown = '00:00:00';
        clearInterval(this.intervalId);
        return;
      }

      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      this.countdown = `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;
    },
    padZero(num) {
      return num < 10 ? `0${num}` : num;
    },
  }
}
</script>

<style scoped>
.countdown {
  font-size: 18px; /* 增大字体大小 */
  color: #f54c4c; /* 改变文字颜色 */
  background-color: #eee1cd; /* 添加背景色 */
  padding: 5px 10px; /* 增加内边距 */
  border-radius: 5px; /* 圆角边框 */
  border: 1px solid #0e0d0d; /* 添加边框 */
}

</style>