// src/axios.js

import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://sunoapi.zhuyit.com',
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 添加 token 或其他请求头处理
    const token = localStorage.getItem('Authorization');
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 处理成功的响应
    return response;
  },
  (error) => {
    // 处理错误的响应
    if (error.response) {
      // 服务器响应了，但状态码不在 2xx 范围内
      switch (error.response.status) {
        case 500:
          // 处理 500 错误
          // 这里可以添加更多的错误处理逻辑，例如显示错误提示或记录日志
          break;
        default:
          // 处理其他错误
      }
    } else if (error.request) {
      // 请求已发出，但没有收到响应
    } else {
      // 发生在设置请求时的错误
      }
    // 返回原始错误对象
    return Promise.reject(error);
  }
);

export default instance;