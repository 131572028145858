<template>
    <v-dialog v-model="localDialog" max-width="500">
        <template v-slot:default>
            <!-- 消息提示 -->
            <v-snackbar :timeout="2000" v-model="snackbar" color="white" vertical>
                <div class="text-subtitle-1 pb-2">提示</div>
                <p>{{ text }}</p>

                <template v-slot:actions>
                    <v-btn color="pink" variant="text" @click="snackbar = false">
                        关闭
                    </v-btn>
                </template>
            </v-snackbar>
            <div v-if="jsApiPc.length === 0 && payOk === false">
                <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
                    <div class="d-flex justify-center align-center mb-6">
                        <v-icon>mdi-credit-card-outline</v-icon>
                        <span class="ml-1">选择支付方式</span>



                    </div>
                    <v-divider></v-divider>
                    <v-btn :loading="loading" class="mt-5" size="large" variant="text" @click="environment" block>
                        <v-avatar>
                            <v-img class="mx-auto" max-width="228" src="../assets/wxpay.png"></v-img>
                        </v-avatar>微信支付
                    </v-btn>
                    <div class="mt-5"></div>
                    <v-btn class="mt-15 ml-2" size="large" variant="plain" @click="sendDataToParent" block>
                        取消支付
                    </v-btn>

                </v-card>
            </div>

            <div v-if="jsApiPc.length > 0">
                <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
                    <div class="d-flex justify-center align-center mb-6">
                        <v-avatar>
                            <v-img class="mx-auto" max-width="228" src="../assets/wxpay.png"></v-img>
                        </v-avatar>
                        <span class="ml-1">微信支付</span>
                    </div>
                    <div class="d-flex justify-center align-center font-weight-bold text-red text-h6">￥{{ num }}</div>
                    <v-divider></v-divider>
                    <div class="d-flex justify-center align-center">
                        <v-img :width="200" aspect-ratio="4/3" cover :src="jsApiPc"></v-img>
                    </div>
                    <div class="mt-5"></div>

                    <div class="d-flex justify-center align-center">打开微信扫一扫
                        
                    </div>
                    <div class="d-flex justify-center align-center mb-6" v-if="remainingTime > 0 && jsApiPc.length > 0">
                        （请在{{ formatRemainingTime }} 内完成支付）
                    </div>

                    
                    <v-btn class="mt-15 ml-2" size="large" variant="plain" @click="cancelPayment" block>
                        取消支付
                    </v-btn>

                </v-card>
            </div>

            <div v-if="payOk">
                <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
                    <div class="d-flex font-weight-bold mb-6">成功提示</div>
                    <v-divider></v-divider>
                    <div class="d-flex justify-center align-center mb-6">
                        
                        <div class="py-12 text-center">
                            <v-icon class="mb-6" color="success" icon="mdi-check-circle-outline" size="128"></v-icon>

                            <div class="text-h5 font-weight-bold">支付成功!感谢您的支持！</div>
                        </div>

                    </div>
                    <v-divider></v-divider>

                    <div class="pa-4 text-end">
                        <v-btn class="text-none" color="medium-emphasis" min-width="92" variant="outlined" rounded
                            @click="closePayOk">
                            关闭窗口
                        </v-btn>
                    </div>

                </v-card>
            </div>


        </template>
    </v-dialog>
</template>

<script>

export default {
    name: 'PayDialog',
    props: {
        open: Boolean,
        onSubmit: Function,
        value: Number,
        num: Number
    },
    data() {
        return {
            text: '',
            snackbar: false,
            someData: null,
            currentEnvironment: '',
            jsApiPc: '',
            loading: false,
            requestId: '',
            remainingTime: 0, // 5 分钟
            countdownInterval: null,
            payOk: false
        };
    },
    created() {
        console.log(this.value)
    },
  watch: {
    open(newVal) {
      if (newVal) {
        // 当对话框打开时重置状态
        this.jsApiPc = '';
        this.requestId = '';
        this.remainingTime = 0;
        clearInterval(this.countdownInterval);
      }
    }
  },
    computed: {
        localDialog: {
            get() {
                return this.open;
            },
            set(value) {
                this.someData = '取消登录';
                this.$emit('cancellation', this.someData);
            },
        },
        formatRemainingTime() {
            const minutes = Math.floor(this.remainingTime / 60);
            const seconds = this.remainingTime % 60;
            return `${minutes}:${seconds.toString().padStart(2, '0')}`;
        }
    },
    mounted() {

    },
    beforeDestroy() {
        clearInterval(this.countdownInterval);
    },
    methods: {
        closePayOk() {
            location.reload();
        },
        handleSubmit(callback) {
            if (this.firstName && this.password) {
                callback({ username: this.firstName, password: this.password });
            } else {
                this.text = '请输入用户名和密码';
                this.snackbar = true;
            }
        },
        sendDataToParent() {
            this.someData = '取消支付';
            this.$emit('cancellation', this.someData);
        },
        cancelPayment() {
            this.jsApiPc = ''
            this.requestId = ''
            this.remainingTime = 0;
            clearInterval(this.countdownInterval);
        },
        startCountdown() {
            this.countdownInterval = setInterval(() => {
                if (this.remainingTime > 0) {
                    this.remainingTime--;
                    if (this.remainingTime % 3 === 0) {
                        this.$axios.get('/pay/resultQuery?taskId=' + this.requestId).then(response => {
                            if (response.data.data === 1) {
                                this.jsApiPc = ''
                                this.requestId = ''
                                clearInterval(this.countdownInterval);
                                this.cancelPayment();
                                this.$emit('paymentSuccess', response.data.data.taskId);
                                this.snackbar = true;
                                this.text = '支付成功！感谢您的支持'
                                this.payOk = true
                            }
                        })
                    }
                } else {
                    clearInterval(this.countdownInterval);
                    this.cancelPayment();
                }
            }, 1000);
        },
        environment() {
            this.loading = true;

            const userAgent = navigator.userAgent.toLowerCase();

            // 检查是否为手机微信浏览器
            const isWeChat = /micromessenger/.test(userAgent);

            // 检查是否为移动设备
            const mobileAgents = ['android', 'iphone', 'windows phone'];
            let isMobile = false;

            for (const agent of mobileAgents) {
                if (userAgent.indexOf(agent) > -1) {
                    isMobile = true;
                    break;
                }
            }

            if (isWeChat && isMobile) {
                this.$axios
                    .post('/pay/jsapi', this.value)
                    .then(res => {
                        window.location.href = res.data.data.data.order_url;
                    }).catch(error => {
                        this.snackbar=true;
                        this.text = error.response.data.message
                    });

            } else if (isMobile) {
                this.$axios
                    .post('/pay/h5', this.value)
                    .then(res => {
                        window.location.href = res.data.data.data;
                    }).catch(error => {
                        this.snackbar=true;
                        this.text = error.response.data.message
                    });
            } else {
                this.$axios
                    .post('/pay/jsapiPc', this.value)
                    .then(res => {
                        console.log(res)
                        this.jsApiPc = res.data.data.data.QRcode_url;
                        this.requestId = res.data.data.request_id;
                        this.remainingTime = 300;
                        this.startCountdown();
                    }).catch(error => {
                        this.snackbar=true;
                        this.text = error.response.data.message
                    });
                    

            }
            this.loading = false
        }
    },

};
</script>

<style scoped></style>