<template>
  <v-responsive class="border rounded">
  <v-app :theme="theme">
    <login-dialog :open="showLoginDialog" @loginSuccess="handleLoginSuccess" @cancellation="handleCancellation" />
    <v-navigation-drawer v-if="windowWidth >= 1280" clipped>
      <v-sheet height="100%" class="d-flex flex-column">
        <v-list dense>
          <v-list-item class="mt-5">
            <v-avatar size="60" style="border: none;">
              <v-img src="../src/assets/1724133623859.jpg"></v-img>
            </v-avatar>
            <span class="logo-text">人人都是音乐家🌟</span>
            <v-list-item-title class="text-h4">
              SUNO-SONG
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            v-for="(item, index) in menuItems"
            :key="index"
            link
            :class="{ 
              'mt-3': index === 0,
              'selected': index === selectedMenuItem 
             }"
            @click="selectItem(index, item.url)"
          >
            <v-list-item-title>
              <v-icon class="mr-1 mb-1">{{ item.icon }}</v-icon>{{ item.displayName }}
            </v-list-item-title>
          </v-list-item>
        </v-list>


        <v-spacer></v-spacer>
        <v-list-item v-if="isLogin" :class="{ 
              'selected': 88 === selectedMenuItem 
             }" link>
          <v-list-item-title><div @click="selectItem(88,'member')"><v-icon class="mr-1 mb-1" color="#CD7F32">mdi-crown</v-icon>会员订阅</div></v-list-item-title>
        </v-list-item>
        
        <v-divider></v-divider>
        
        <v-list dense>
          <v-list-item v-if="isLogin" :class="{ 
              'selected': 99 === selectedMenuItem 
             }" link>
          <v-list-item-title @click="selectItem(99,'profile')">
           

            <!-- pc端个人头像 -->
            <div class="mb-1">
        <v-menu
        class="mr-1"
        rounded
      >
        <template v-slot:activator="{ props }">
          <v-btn
            icon
            v-bind="props"
          >
            <v-avatar
            >
            <v-img :src="user.avatarUrl"></v-img>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <div class="mx-auto text-center">
              <v-avatar
              >
                <v-img :src="user.avatarUrl"></v-img>
              </v-avatar>
              <h3>{{ user.nickname }}</h3>
              <p class="text-caption mt-1" :style="{'color': membershipColor}">
                <v-icon class="mb-1" :icon="membershipIcon"></v-icon>{{ membershipText }}
              </p>
              <p class="text-caption mt-1" v-if="user.expirationTime">
                会员到期:{{ user.expirationTime }}
              </p>
              <p class="text-caption mt-1">
                剩余积分:{{ user.integral }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn
                variant="text"
                rounded
                @click="selectItem(99,'profile')"
              >
                个人中心
              </v-btn>
              <v-divider class="my-3"></v-divider>
              <v-btn
                variant="text"
                rounded
                @click="logout"
              >
                退出登录
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-menu>
           {{ user.nickname }}
          
        </div>
        
          </v-list-item-title>
          </v-list-item>
          <!-- pc端个人头像 -->

          <v-list-item v-else @click="toggleLoginDialog" link>
          <v-list-item-title><div><v-icon class="mr-1" color="#FF7F24">mdi-login-variant</v-icon><span class="ml-1" style="color:#FF7F24">立即登录</span></div></v-list-item-title>
          </v-list-item>

          <v-divider v-if="isLogin"></v-divider>

          <v-list-item
            v-for="(item, index) in menuItems2"
            :key="index"
            :class="{ 
              'selected': index+13 === selectedMenuItem 
             }"
            link
            @click="selectItem(index+13,item.url)"
          >

            <v-list-item-title>
              <v-icon class="mr-1 mb-1">
                {{ item.icon }}
              </v-icon>
              {{ item.displayName }}
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item-title class="ml-4 mt-2 mb-1" @click="onClick"><div><v-icon class="mr-1 mb-1">{{ theme === 'light' ? 'mdi-weather-night' : 'mdi-weather-sunny' }}</v-icon><span class="ml-1">{{ theme === 'light' ? '夜间模式' : '日间模式' }}</span></div></v-list-item-title>
        </v-list>
      </v-sheet>
    </v-navigation-drawer>

    <v-app-bar v-if="windowWidth < 1280" :elevation="2">
      <template v-slot:prepend  class="ma-0">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      </template>
      <v-app-bar-title class="ma-0">
        <v-avatar>
          <v-img src="../src/assets/1724133623859.jpg"></v-img>
        </v-avatar>
        <span class="title-name">
          AI音乐创作
        </span>
      </v-app-bar-title>

     

      

      <!-- 手机端用户头像 -->
      <div class="mr-4">
        <div v-if="isLogin">
        <v-menu
        min-width="200px"
        rounded
      >
        <template v-slot:activator="{ props }">
          <v-btn
            icon
            v-bind="props"
          >
            <v-avatar
            >
            <v-img :src="user.avatarUrl"></v-img>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <div class="mx-auto text-center">
              <v-avatar class="mb-1"
              >
                <v-img :src="user.avatarUrl"></v-img>
              </v-avatar>
              <h3>{{ user.nickname }}</h3>
              <p class="text-caption mt-1" :style="{'color': membershipColor}">
                <v-icon class="mb-1" :icon="membershipIcon"></v-icon>{{ membershipText }}
              </p>
              <p class="text-caption mt-1" v-if="user.expirationTime">
                到期时间:{{ user.expirationTime }}
              </p>
              <p class="text-caption mt-1">
                剩余积分:{{ user.integral }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn
                variant="text"
                rounded
                @click="selectItemAndCloseDrawer('profile')"
              >
                个人中心
              </v-btn>
              
              <v-divider class="my-3"></v-divider>
              <v-btn
                variant="text"
                rounded
                @click="logout"
              >
                退出登录
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
        <div class="mb-1" v-else>
          <v-btn @click="toggleLoginDialog">
          <v-icon class="mt-1">mdi-login-variant</v-icon>
          <span  class="mt-1">登录</span>
          </v-btn>
        </div>
      </div>
       <!-- 手机端会员充值 -->
       <div  @click="selectItem(88,'member')" class="mr-5" v-if="isLogin">
        <v-icon color="#CD7F32">mdi-crown</v-icon>
       </div>
      
       <!-- 黑夜模式开关 -->
       <div  @click="onClick" class="mr-5">
        <v-icon>
          {{ theme === 'light' ? 'mdi-weather-night' : 'mdi-weather-sunny' }}
        </v-icon>
        </div>
    </v-app-bar>

<!-- 抽屉 -->

      <v-navigation-drawer
              v-model="drawer"
              location="left" 
              temporary
            >
            <v-list dense>
            <v-list-item
              v-for="(item, index) in items"
              :key="index"
              :class="{ 'highlighted': selectedItemIndex === index }"
              @click="selectItemAndCloseDrawer(item.value)"
            >
              <v-list-item-title><v-icon>{{ item.icon }}</v-icon>&nbsp;{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      <!-- 抽屉 -->

    <v-main style="min-height: 300px;">
      <router-view />
    </v-main>


    <v-snackbar
      v-model="snackbar"
    >
      {{ text }}

      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="snackbar = false"
        >
          关闭
        </v-btn>
      </template>
    </v-snackbar>

    <!-- 音乐缩略播放器 -->
    <!-- <div v-if="windowWidth < 1280">
      <v-app-bar></v-app-bar>
    </div> -->
   
    

    <div v-if="windowWidth < 1280">

      <!-- 移动端导航栏 -->
      <v-bottom-navigation v-model="value" color="teal" grow>
      
        <v-btn @click="selectItemAndCloseDrawer('home')">
          <v-icon>mdi-music</v-icon>
          音乐广场
        </v-btn>
        <v-btn @click="selectItemAndCloseDrawer('create')">
          <v-icon>mdi-pencil-outline</v-icon>
          音乐创作
        </v-btn>
       
        <v-btn @click="selectItemAndCloseDrawer('mywork')">
          <v-icon>mdi-music-box-multiple</v-icon>
          我的作品
        </v-btn>
        <v-btn @click="selectItemAndCloseDrawer('profile')">
          <v-icon>mdi-home-account</v-icon>
          个人中心
        </v-btn>
        </v-bottom-navigation>
      

    </div>

   
  </v-app>
</v-responsive>
</template>

<style>
.title-name {
  font-size: 20px;
  margin-top: 10px;
}
.logo-text {
  font-size: 16px;
  margin-left: 18px;
}
.selected {
  background-color: #e0e0e0; /* 示例颜色 */
  font-weight: bold;
}
</style>

<script>
import LoginDialog from './components/LocalDialog.vue';

export default {
  name: 'App',
  components: {
    LoginDialog,
  },
  data: () => ({
    user: {
        id: 0,
        nickname: '0',
        integral: 0,
        avatarUrl:"",
        membershipLevel:0,
        expirationTime:null
      },
    isLogin: false,
    theme:'light',
    selectedMenuItem: null,
    value: null,
    sheet: false,
    showLoginDialog: false,
    currentTime: '0:00',
    totalTime: '0:00',
    progress: 0,
    play:'mdi-play',
    drawer: false,
    windowWidth: window.innerWidth,
    text:'',
    snackbar: false,
    menuItems: [
      { displayName: '音乐广场', icon: 'mdi-music', url: 'home' },
      { displayName: '音乐创作', icon: 'mdi-pencil-outline', url: 'create' },
      { displayName: '我的作品', icon: 'mdi-music-box-multiple', url: 'mywork' },
    ],
    menuItems2: [
      { displayName: '联系我们', icon: 'mdi-phone', url: 'contactUs' },
      { displayName: '关于我们', icon: 'mdi-information-variant', url: 'about' },
      // { displayName: '使用指南', icon: 'mdi-compass-outline', url: 'guides' },
      { displayName: '常见问题', icon: 'mdi-information-outline', url: 'faq' },
    ],
    items: [
       
        {
          title: '音乐广场',
          value: 'home',
          icon:'mdi-music'
        },
        {
          title: '音乐创作',
          value: 'create',
          icon:'mdi-pencil-outline'
        },
        {
          title: '我的作品',
          value: 'mywork',
          icon:'mdi-home-account'
        },
        {
          title: '个人中心',
          value: 'profile',
          icon:'mdi-music'
        },
        {
          title: '联系我们',
          value: 'contactUs',
          icon:'mdi-phone'
        },
        {
          title: '关于我们',
          value: 'about',
          icon:'mdi-information-variant'
        },
        // {
        //   title: '使用指南',
        //   value: 'guides',
        //   icon:'mdi-compass-outline'
        // },
        {
          title: '常见问题',
          value: 'faq',
          icon:'mdi-information-outline'
        },
      ],
    
  }),
    //监听抽屉菜单栏
    watch: {
      group () {
        this.drawer = false
      },
    },
    created() {
      this.$axios.get('/user/select').then(response => {
      if (response.data.code === 2000) {
        this.isLogin = true;
        const userdata = response.data.data;
        this.user.id = userdata.id;
        this.user.nickname = userdata.nickname;
        this.user.integral = userdata.integral;
        this.user.avatarUrl = userdata.avatarUrl;
        this.user.membershipLevel = userdata.membershipLevel;
        this.user.expirationTime = this.formatDate(userdata.expirationTime);
      }
    }).catch(error => {
      this.isLogin=false;     
    });
    },
  methods: {
    onClick () {
    this.theme = this.theme === 'light' ? 'dark' : 'light'
  },
    logout(){
      localStorage.removeItem('Authorization');
      this.text = '退出登录';
      this.snackbar=true;
      this.isLogin=false;
      location.reload();
    },
    selectItem(index, url) {
    this.selectedMenuItem = index; // 设置当前选中的菜单项

    this.selectItemAndCloseDrawer(url); // 调用原有方法
  },    //显示登录窗口
    toggleLoginDialog() {
      this.showLoginDialog = !this.showLoginDialog;
    },
    formatDate(dateString) {
      const date = new Date(dateString);

      function padZero(num) {
        return num.toString().padStart(2, '0');
      }

      const year = date.getFullYear();
      const month = padZero(date.getMonth() + 1);
      const day = padZero(date.getDate());
      return `${year}-${month}-${day}`;
    },
    handleLoginSuccess(data) {
      // 进行其他操作，如重定向等
    },
    handleCancellation(data) {
      this.showLoginDialog = false;
    },
    updateWindowDimensions() {
      this.windowWidth = window.innerWidth;
    },
    selectItemAndCloseDrawer(items){
      //按菜单value进行路由名称匹配，如果没有则不跳转并关闭抽屉
      try {
        // 更新选中的项的索引，用于选项高亮
        this.selectedItemIndex = this.items.findIndex(itema => itema.value === items);
        this.$router.push({ name: items });
        this.drawer = false;
      }catch(error){
      } finally {
        this.drawer = false;
      }
        
    },
   
  },
  computed: {
    membershipColor() {
      switch (this.user.membershipLevel) {
        case 0:
          return 'black';
        case 1:
          return '#FF7F24';
        case 2:
          return '#FF7F24';
        case 3:
          return '#FF7F24';
        case 4:
          return '#FF7F24';
        case 5:
          return '#FF7F24'
        case 6:
          return '#FF7F24'
        default:
          return 'black';
      }
    },
    membershipIcon() {
      switch (this.user.membershipLevel) {
        case 0:
          return 'mdi-account';
        case 1:
          return 'mdi-crown';
        case 2:
          return 'mdi-crown';
        case 3:
          return 'mdi-crown';
        case 4:
          return 'mdi-crown';
        case 5:
          return 'mdi-crown';
        case 6:
          return 'mdi-crown';
        default:
          return 'mdi-account';
      }
    },
    membershipText() {
      switch (this.user.membershipLevel) {
        case 0:
          return '普通用户';
        case 1:
          return '体验会员';
        case 2:
          return '高级会员';
        case 3:
          return '高级会员';
        case 4:
          return '高级会员';
        case 5:
          return '超级会员';
        case 6:
          return '超级会员plus';
        default:
          return '普通用户';
      }
    }
  },

  mounted() {
    window.addEventListener('resize', this.updateWindowDimensions);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
}
</script>
