// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  // 主题配置
  theme: {
    dark: false,
    themes: {
      light: {
        fonts: {
          body: 'Roboto, sans-serif',
        },
      },
    },
  },

  // 图标配置
  icons: {
    iconfont: 'mdi', // 使用 Material Design Icons
  },
});