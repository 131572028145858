<!-- LoginDialog.vue -->
<template>
    <v-dialog v-model="localDialog" max-width="500">
        <template v-slot:default>
            <div>
                
                
                <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
                        
                    <div class="d-flex justify-center align-center mb-6">
                        <v-avatar class="mr-3">
                            <v-img class="mx-auto" max-width="228" src="../assets/1724133623859.jpg"></v-img>
                        </v-avatar>
                        <span>立即登录，开启创作之旅</span>
                        <!-- 消息提示 -->
                        <v-snackbar
                                :timeout="2000"
                                v-model="snackbar"
                                color="white"
                                vertical
                                >
                                <div class="text-subtitle-1 pb-2">提示</div>
                                <p>{{ text }}</p>

                                <template v-slot:actions>
                                    <v-btn
                                    color="pink"
                                    variant="text"
                                    @click="snackbar = false"
                                    >
                                    关闭
                                    </v-btn>
                                </template>
                                </v-snackbar>
                    </div>                    
                   

                    <div class="text-subtitle-1 text-medium-emphasis">手机号</div>

                    <v-text-field density="compact" placeholder="请输入手机号" prepend-inner-icon="mdi-cellphone"
                    v-model="firstName"  variant="outlined"></v-text-field>

                    <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
                        验证码

                        <span v-if="isCode===0" class="text-body-2 text-decoration-none text-blue" @click="getCode" rel="noopener noreferrer"
                            target="_blank">
                            点击获取验证码</span>
                            
                        <span v-if="isCode===1" class="text-body-2 text-decoration-none text-grey" @click="" rel="noopener noreferrer"
                            target="_blank">
                            重新获取( {{ remainingTime }} 秒)</span>
                            
                        <span v-if="isCode===2" class="text-body-2 text-decoration-none text-blue" @click="getCode" rel="noopener noreferrer"
                            target="_blank">
                        重新获取验证码</span>
                    </div>

                    <v-text-field :type="text" density="compact" placeholder="输入您获取到的验证码"
                    v-model="code" prepend-inner-icon="mdi-numeric" variant="outlined"></v-text-field>
                    
                    <v-card
        class="mb-5"
        color="surface-variant"
        variant="tonal"
      >
        <v-card-text class="text-medium-emphasis text-caption">
            <p>如您尚未注册，我们将为您自动创建账户并登录。</p>
            
                登录即代表同意
    <v-dialog max-width="800">
      <template v-slot:activator="{ props: activatorProps }">
        <span
         class="pa-0 ma-0"
         color=""
          v-bind="activatorProps"
          style="font-size: 12px;color:royalblue" 
        >《用户协议》</span>
      </template>

      <template v-slot:default="{ isActive }">
        <v-card title="SunoSong AI 用户协议">
          <template v-slot:text>
            一、接受条款
            本《SunoSong AI 用户协议》（以下简称“本协议”）由铸易工具团队（以下简称“我们”或“SunoSong AI”）制定，适用于您使用 SunoSong AI 应用（以下简称“SunoSong AI”或“本应用”）的各项服务。在注册成为用户之前，请您务必仔细阅读并充分理解本协议及其附件（包括但不限于《SunoSong AI 隐私政策》）。您的注册、登录或使用行为将被视为已同意接受本协议的全部条款。同时，您和 SunoSong AI 双方均应遵守国家政策、法律法规及本应用不时更新的指引和规则，这些内容作为本协议的一部分，对双方具有约束力。
            <br><br>
            二、条款的修改
            为了适应国家政策或法律法规的变化，我们可能会适时更新本协议条款。更新后的协议将在本应用内以通知或公告的形式告知您，您可以随时查阅最新版本。继续使用 SunoSong AI 服务，即表示您接受修订后的协议。
            <br><br>
            三、注册与账户
            注册方式与授权
            为了使用 SunoSong AI 服务，您需要通过手机号码进行注册。请确保所使用的手机号码未与现有的 SunoSong AI 账户绑定，且未被我们封禁。根据相关法律法规的要求，我们可能调整注册和绑定方式，您应予以配合。在注册过程中，您授权我们提取、使用和公开必要的信息以完成注册。
            <br><br>
            资料更新与责任
            您应对注册时及后续更新的手机号码、名称、头像等账户资料的准确性和合法性负责。若提供违法、不道德或违反 SunoSong AI 用户行为规范的资料，或存在恶意操作，我们有权限制、暂停或永久封禁您的账户。
            <br><br>
            内容责任
            您在 SunoSong AI 上发布的内容，无论是公开还是私密，均由您自行承担责任。我们仅为用户提供内容存储空间，不对内容的准确性、完整性或品质作出保证。如遇不适宜内容，您可以通过举报功能向我们反馈，我们将依法依规采取相应措施。
            <br><br>
            四、账户、密码与安全
            完成注册后，您可使用注册手机号码登录您的 SunoSong AI 账户。您应对账户安全负全责，包括但不限于：
            确保账户信息保密，若发现未经授权使用或安全问题，应及时通知我们。
            对因您未妥善保管账户信息导致的自身、我们或第三方损失，您应在过错范围内承担责任。
            在我们判定您的账户存在异常风险时，我们有权临时冻结账户。如需解冻，请按指定流程申请。
            <br><br>
            五、付费功能
            SunoSong AI 的部分增值服务需付费使用。您在购买前应详细了解服务内容、计费方式、续费期限等信息，如有疑问可咨询客服。付费成功后，相关服务即时开通。购买后，除因我们依据法律法规对违规用户进行处理外，您不得要求修改或退款。如需开具发票，税费由您承担，相关运费、快递费等由您自理。
            <br><br>
            六、个人隐私信息保护
            您在注册和使用 SunoSong AI 服务时，可能需要提供个人身份信息。请确保提供的信息真实、完整、合规。有关个人隐私信息的保护，我们遵循《SunoSong AI 隐私政策》，您可通过查阅该政策了解详情。
            <br><br>
            七、用户行为规范
            您承诺在使用 SunoSong AI 时不进行任何违法或不正当活动，遵守本协议及 SunoSong AI 用户行为规范。具体行为规范请参见相关附件。
            <br><br>
            八、国际使用注意事项
            您理解并同意遵守国际互联网相关法律法规及您所在国家或地区的相关规定，特别是在信息输出方面的法规。
            <br><br>
            九、用户发布内容的许可
            您在 SunoSong AI 上发布的内容，应确保拥有相应权利或已取得合法授权，且不侵犯任何第三方权益。您特此授予我们全球范围内、免费、非独占、可转许可和可转让的使用权，允许我们在法律允许范围内使用这些内容。
            <br><br>
            十、赔偿责任
            因您使用本服务、发布内容或违反本协议导致我们或关联方遭受任何索赔、损失、费用（包括合理律师费），您应予赔偿，并承担相应法律责任。
            <br><br>
            十一、数据储存与处理
            我们根据法律法规要求储存与您相关的数据，并在您提出合法请求时进行数据删除。
            <br><br>
            十二、服务变更、中断与终止
            在法律法规允许范围内，我们可能因各种原因调整、暂停或终止部分或全部服务（包括付费服务），并尽可能提前通知。对于服务变更、中断或终止，我们将提供等值替代服务或按实际使用情况退还未使用部分的费用。
            <br><br>
            十三、免责条款
            对于因第三方原因给您造成的损害，您应直接向侵权方主张权利，我们将在法律法规规定范围内予以配合。
            <br><br>
            十四、不可抗力
            因台风、地震、网络攻击、系统故障、法律法规变动等不可抗力导致合同无法履行或需要修改的，双方均不承担违约责任。
            <br><br>
            十五、SunoSong AI 商标信息
            SunoSong AI 及其相关标识均为我们的注册商标。未经我们书面同意，您不得擅自展示、使用或以其他方式处理这些商标。
            <br><br>
            十六、法律适用与争议解决
            本协议、社区指导原则和免责声明构成您与 SunoSong AI 的完整协议。您使用第三方内容或软件时，还需遵守相关附加条款。本协议及双方关系受中华人民共和国法律管辖。如发生争议，双方应先协商解决，协商不成的，提交北京仲裁委员会仲裁，仲裁裁决为终局，对双方均有约束力。
 <br><br>
            十七、SunoSong AI 用户行为规范
            请参阅单独的《SunoSong AI 用户行为规范》，其中详细列出了您在使用 SunoSong AI 时应遵守的具体行为准则、违规行为及相应的处罚机制。您使用 SunoSong AI 即表示接受并同意受该规范约束。          </template>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              text="取消"
              variant="text"
              @click="isActive.value = false"
            ></v-btn>

            <v-btn
              color="surface-variant"
              text="同意"
              variant="flat"
              @click="isActive.value = false"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
                
                和
                <v-dialog max-width="800">
      <template v-slot:activator="{ props: activatorProps }">
        <span
         class="pa-0 ma-0"
         color=""
          v-bind="activatorProps"
          style="font-size: 12px;color:royalblue" 
        >《隐私政策》</span>
      </template>

      <template v-slot:default="{ isActive }">
        <v-card title="SunoSong AI 隐私政策">
          <template v-slot:text>
            欢迎使用铸易工具团队（以下简称“我们”）所提供的 SunoSongAI 音乐相关产品和服务。我们深知个人信息对您的重要性，也深知您对我们的信任之珍贵。为此，我们严格遵守法律法规，采取有力的安全保护措施，以确保您的个人信息得到妥善管理和保护。这份《SunoSongAI音乐隐私政策》（以下简称“本政策”）旨在清晰阐述在您使用我们的产品和服务过程中，我们如何收集、使用、共享、存储和保护您的个人信息，以及您如何管理这些信息。在开始使用我们的产品和服务之前，请您务必详阅并理解本政策，特别是我们以粗体或粗体下划线标识的关键条款。除本政策外，我们还可能通过即时提示（如弹窗、页面通知等）和功能更新说明等方式，就特定场景下的信息收集目的、范围及使用方式向您进行补充说明，这些补充内容同样构成本政策的有效组成部分。
            <br><br>
            一、适用范围
            本政策适用于您通过 SunoSongAI 音乐网站获取的各项产品及/或服务。对于关联公司提供的、单独设有隐私政策的产品及/或服务，适用相应隐私政策。本政策仅涵盖本政策所述的信息收集使用活动，不涉及第三方提供的其他服务，后者应遵循其提供的个人信息收集使用规则。
            <br><br>
            二、信息收集及使用
            在您使用 SunoSongAI 音乐产品及/或服务的过程中，我们可能收集和使用两类个人信息：
            基本功能所需信息：为提供服务的基本功能并履行法律法规义务，您需授权我们收集和使用必要的个人信息。如果您拒绝提供，可能无法正常使用相关服务。
            附加功能信息：为提供附加功能，我们可能收集您选择同意的额外信息。您有权选择是否开启这些功能并提供对应信息，拒绝提供不影响基本功能使用。
            我们为您提供以下具体功能时，可能涉及的信息收集与使用：
            账号注册/登录
            账号注册：根据国家法律法规的实名认证要求，您需提供手机号码并通过验证创建账号。
            账号登录：使用注册账号登录时，需提供手机号码进行验证。
            浏览/生成音乐/分享/下载
            为提供一致化服务，我们需要收集您的设备信息和日志信息，包括浏览记录、生成音乐、分享记录、下载记录、访问日期等。这些信息对于实现功能至关重要，若不提供，可能导致功能无法正常使用。在下载时，我们会请求您的存储权限。
            AI写歌
            您可输入指令（如“为XXX写一首主题为XXXXX的歌”），选择曲风，我们会据此生成内容。您输入的指令中是否包含个人信息、包含何种信息完全由您决定。如包含他人信息，请确保已获得合法授权。
            下单与支付
            在购买产品及/或服务（如生成AI歌手）时，我们需收集与交易相关的必要信息，包括交易详情、金额、下单时间、订单号、状态、支付方式和状态等，以保障交易安全、查询订单、提供客户服务。
            客服及争议处理
            当您联系我们或申请争议解决时，为确保您的账户安全和提供优质服务，可能需要您提供必要的个人信息以验证身份，并记录相关沟通内容。
            提供安全保障
            为保障您和其他用户的安全，预防安全风险，我们可能收集设备信息以评估账号及交易风险、进行身份验证、检测及应对安全事件。
            其他附加服务
            系统权限服务：为提升体验，我们可能通过开启系统权限收集和使用个人信息。您可随时开启或关闭这些权限，开启即表示授权，关闭则撤销授权。
            Cookie 和同类技术：我们使用 Cookie 和类似技术收集和存储您访问、使用本产品时的信息，以提升服务安全性、效率及个性化体验。您可更改浏览器设置管理这些技术的使用。
            <br><br>
            三、信息的共享、转移、公开
            信息共享
            基本原则：
            合法正当与最小必要原则：我们仅在必要范围内，遵循法律法规要求，与合作方共享或委托处理您的个人信息，以确保和优化产品/服务的正常运行。
            用户知情权与决定权最大化原则：我们将充分尊重并保护您的知情权与决定权，确保您对个人信息处理行为有清晰了解并能做出自主决策。
            安全保障能力最强化原则：我们将严格审查合作方的安全保障能力，通过协议约束其对个人信息的安全处理，并持续监督其合规性。
            合作方范围：涉及关联方、第三方提供服务的特定功能和场景中，合作方包括我们的关联方与第三方。
            委托处理：我们仅在合法、正当、必要、特定、明确的目的下，委托合作方处理您的信息。合作方须严格遵守协议规定，仅接触履行职责所需信息，不得作其他用途。未经您的另行同意，不得将信息用于委托范围外。
            共同处理：在共同处理个人信息的场景中，我们将与合作方签订相关协议，明确各方权利义务，确保遵守法律规定，保护信息安全。
            信息转移
            若因合并、分立、解散、破产等原因需转移您的个人信息，我们将告知接收方名称、联系方式，确保接收方继续履行本政策及相关法定义务，变更处理目的、方式时，需重新获取您的同意。
            公开披露
            我们仅在以下情况下公开您的个人信息：经您主动选择或单独同意；针对您违反法律法规、平台规则的行为，或为保护用户及公众人身财产安全，必要时公开相关违规信息及已采取措施。
            停止运营
            若停止运营产品及/或服务，我们将停止收集个人信息，通过通知或公告告知您，并对已持有的相关个人信息进行删除或匿名化处理。
            法律豁免情形
            在以下情况下，我们共享、转移、公开您的个人信息无需征得您的授权同意：
            履行合同所必需；
            履行法定职责或法定义务；
            应对突发公共卫生事件或紧急保护生命健康、财产安全；
            新闻报道、舆论监督等公共利益需求；
            您自行公开或经合法途径已公开的个人信息。
            <br><br>
            四、您的权利
            您可通过本政策提供的联系方式联系我们，身份核实后，我们将为您提供信息查阅、管理服务，遵照法律法规响应您的请求：
            
            删除
            在以下情形，您可请求删除个人信息：
            处理目的已实现、无法实现或不再必要；
            产品或服务停止，或保存期限届满；
            撤回处理个人信息的同意；
            我们违法或违约处理个人信息；
            法律法规规定的其他情形。
            您可在“我的”页面进行删除操作，如有困难，可通过客服联系方式寻求协助。
            若我们响应您的删除请求，将通知从我们处获取您信息的主体，要求其及时删除（除非法律法规另有规定或已独立获得您的授权）。由于技术限制，删除请求可能无法立即影响备份系统中的信息，我们将安全存储并限制对此类信息的进一步处理，直至备份清除或实现匿名化。
            注销账号
            您可通过客服联系方式申请注销账号。注销后，我们将停止提供产品与/或服务，依法删除或匿名化处理您的个人信息。如删除技术上困难，将进行匿名化处理或仅做存储及必要的安全保护，除非法律法规另有规定。
            响应请求
            对于您的请求，您或相关有权主体可通过客服或直接向个人信息保护专职部门发起投诉，我们将在15个工作日内回复。为确保安全，处理请求前可能需要验证您的身份。对于合理请求，我们原则上不收费；对于重复、过度或复杂的请求，我们保留酌情收费的权利。对于与您身份无关、重复申请、技术实现困难、可能侵犯他人权益或不切实际的请求，我们可能拒绝。
            <br><br>
            五、信息的存储（续）
            存储安全（续）
            管理措施：我们设立个人信息保护专职部门，指定专人负责儿童个人信息保护，通过培训课程和考试提升员工对个人信息保护的认识，构建以数据为核心、覆盖全生命周期的数据安全管理体系。
            安全事件响应：发生个人信息安全事件导致您的合法权益受损时，我们将迅速启动应急预案，采取合理措施降低影响。按规定告知您事件基本情况、可能影响、已采取或拟采取的处置措施、防范建议及补救措施，通过短信、电话、推送通知等渠道通知您，或在难以逐一告知时发布公告。同时，我们将按照监管要求上报事件处置情况。
            账号安全防范：在使用 SunoSongAI 产品与服务时，请妥善保护个人信息，仅在必要时向他人提供，避免通过非官方交易工具进行交易，以防信息泄露或遭遇电信诈骗。如担心账号安全，请立即联系客服，我们将根据您的申请采取相应措施。
            <br><br>
            六、政策更新
            为不断提升产品及/或服务体验，我们持续改进产品、服务和技术。在推出新服务或业务流程变更时，我们可能更新隐私政策，但未经您明确同意，您的权利将始终遵循本政策。所有政策更新将在专用页面发布。针对重大变更，我们将通过平台公告、系统消息等方式提供显著通知。 重大变更包括但不限于：

            业务模式变化：如个人信息处理目的、类型、使用方式的根本性调整。
            主体变更：业务调整、并购导致的隐私政策主体变更，新主体变更处理目的与方式。
            共享或公开对象变化：个人信息主要共享或公开对象的显著变动。
            用户权利变更：个人信息权利及其行使方式的重大调整。
            负责人及投诉渠道变动：个人信息保护负责人联系方式及投诉渠道的更改。
            影响评估结果：个人信息保护影响评估报告揭示产品对个人权益有重大影响。 此外，我们将对本政策的历史版本进行存档，便于您随时查阅。
            <br><br>
            七、未成年人保护
            我们高度重视未成年人的个人信息保护。尽管我们并未专门针对未成年人提供产品或服务，且多数服务无需身份证实名认证，但我们通过用户在特定产品或服务中的实名认证状态或是否启用“青少年模式”，判断其是否为未成年人。 如果您是未成年人：

            未满十八周岁者，在使用我们的服务前，应取得父母或监护人同意，并在其指导下使用。
            未满十四周岁者，请在父母或监护人陪同下阅读本隐私政策，且在征得其同意后使用我们的服务或提供个人信息。 如果您是未成年人的父母或监护人：
            请关注未成年人是否在取得您的授权同意后使用我们的服务。
            如对未成年人的个人信息有任何疑问，请联系我们的个人信息保护专职部门。
            附录：相关定义
            个人信息：以电子或其他形式记录，能够直接或间接识别自然人身份的各种信息，不包括经过匿名化处理的信息。
            敏感个人信息：一旦泄露或非法使用，可能导致自然人人格尊严受损或人身、财产安全受威胁的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及十四周岁以下未成年人的个人信息。
            儿童：指未满十四周岁的未成年人。
            <br><br>
            生效时间
            2024年 9月 1 日
        </template>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              text="取消"
              variant="text"
              @click="isActive.value = false"
            ></v-btn>

            <v-btn
              color="surface-variant"
              text="同意"
              variant="flat"
              @click="isActive.value = false"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
        </v-card-text>
      </v-card>


                    <v-btn class="mb-3" color="blue" size="large" variant="tonal" block @click="login">
                        立即登录
                    </v-btn>

                    <v-btn class="mb-8" color="red" size="large" variant="tonal" block @click="sendDataToParent">
                        取消登录
                    </v-btn>

                    <!-- <v-card-text class="text-center">
                        <a class="text-blue text-decoration-none" rel="noopener noreferrer" target="_blank" @click="otherLogin">
                            其他登录方式 <v-icon icon="mdi-chevron-right"></v-icon>
                        </a>
                    </v-card-text> -->
                </v-card>
            </div>
            
        </template>

        
    </v-dialog>
</template>

<script>

export default {
    name: 'LoginDialog',
    props: {
        open: Boolean,
        onSubmit: Function,
    },
    data() {
        return {
            firstName: '',
            code: '',
            text: '',
            snackbar: false,
            someData: '',
            remainingTime: 60,
            countdownActive: false,
            countdownInterval: null,
            isCode: 0,
        };
    },
    computed: {
        localDialog: {
            get() {
                return this.open;
            },
            set(value) {
                this.someData = '取消登录';
                this.$emit('cancellation', this.someData);
            },
        },
    },
    methods: {
        startCountdown() {
            if (!this.countdownActive) {
                this.countdownActive = true;
                this.countdownInterval = setInterval(() => {
                this.remainingTime--;
                if (this.remainingTime <= 0) {
                    this.stopCountdown();
                }
                }, 1000);
            } else {
                this.stopCountdown();
            }
    },
    stopCountdown() {
      clearInterval(this.countdownInterval);
      this.countdownActive = false;
      this.remainingTime = 60; // 重置为 60 秒
      this.isCode = 2;
    },
        //其他登录方式
        otherLogin() {
            this.snackbar = true;
            this.text='暂未开放';
        },
        getCode() {
           if(this.firstName===''){
            this.text = '请输入手机号';
            this.snackbar = true;
            return;
           }

           this.$axios.post('/login/code', {
                phoneNumber: this.firstName
            }).then((response) => {
                if(response.data.code === 2000){
                    this.text = '验证码发送成功，10分钟内有效';
                    this.snackbar = true;
                    this.isCode = 1;
                    this.startCountdown();
                }
            }).catch(error => {
                this.text = error.response.data.message;
                this.snackbar = true;
            })
        },
        handleSubmit(callback) {
            if (this.firstName && this.password) {
                callback({ username: this.firstName, password: this.password });
            } else {
                this.text = '请输入用户名和密码';
                this.snackbar = true;
            }
        },
        sendDataToParent() {
            this.someData = '取消登录';
            this.$emit('cancellation', this.someData);
        },

        login() {
            this.$axios.post('/login/phone', {
                phoneNumber: this.firstName,
                code: this.code
            }).then((response) => {
                if (response == null) {
                    this.someData = '网络错误，请稍后再试';
                    this.$emit('loginSuccess', this.someData);
                }

                if (response.data.code === 2000) {
                    localStorage.setItem('Authorization', response.data.data.token);
                    
                    this.someData = '登录成功';
                    this.snackbar = true;
                    this.text='登录成功,请稍后';
                    this.$emit('loginSuccess', this.someData);
                    location.reload();
                } else {
                    this.snackbar = true;
                    this.text= response.data.message;
                }
            })
                .catch(error => {
                    this.snackbar = true;
                    this.text= error.response.data.message;
                    this.someData = error.response.data.message;
                    this.$emit('loginSuccess', this.someData);
                });
        }
    },
};
</script>

<style scoped></style>