<template>
    <!-- 消息提示 -->
  <v-snackbar
      :timeout="2000"
      v-model="snackbar"
      color="white"
      vertical
    >
    <div class="text-subtitle-1 pb-2">提示</div>
      <p>{{ text }}</p>

      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="snackbar = false"
        >
          关闭
        </v-btn>
      </template>
    </v-snackbar>
     <v-parallax
    src="../assets/bg.png"
  >
  
    <v-container fluid>
        <v-row justify="center">
            <v-col cols="12" sm="8" md="6">
                <v-card>
                    <v-img :src="imageUrl" height="280px"
                        cover>
                        <v-card-title class="text-center">
                            <span class="headline">{{ title }}</span>
                            <div class="headline-two">歌手：{{ userName }}</div>
                        </v-card-title>
                    </v-img>
                    <v-card-text>
                        <div><span style="font-weight: bold;font-size: 20px;">歌曲：{{ title }}</span></div>
                        <v-divider class="mb-2 mt-2"></v-divider>
                        <div class="ml-1"><span>歌手：</span>{{ userName }}</div>
                        <div class="ml-1"><span>风格：</span>{{ translateMusicGenreToChinese(tags) }}</div>
                        <v-divider class="mb-2 mt-2"></v-divider>
                    </v-card-text>
                    <div style="display: flex; align-items: center;">
                        <v-slider v-model="currentTime" min="0" :max="duration" :label="formattedCurrentTimeLabel"
                            @start="sliderStart" @end="sliderEnd">
                        </v-slider>
                        <span style="margin-left: 15px; margin-bottom: 22px; margin-right: 15px; color: slategrey;">{{
                            formattedDuration }}</span>
                    </div>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="kuaitui">
                            <v-icon>mdi-skip-backward</v-icon>
                        </v-btn>
                        <v-btn icon @click="prevTrack">
                            <v-icon>mdi-skip-previous</v-icon>
                        </v-btn>
                        <v-btn :icon="playing ? 'mdi-pause' : 'mdi-play'" @click="togglePlay">
                            <v-icon>{{ playing ? 'mdi-pause' : 'mdi-play' }}</v-icon>
                        </v-btn>
                        <v-btn icon @click="nextTrack">
                            <v-icon>mdi-skip-next</v-icon>
                        </v-btn>
                        <v-btn icon @click="kuaijin">
                            <v-icon>mdi-skip-forward</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-content>
                                    <pre class="text-subtitle-2 ma-2 custom-pre">
{{ lyric }}
</pre>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</v-parallax>
</template>

<script>
export default {
    name: "musicPlayer",
    data() {
        return {
            songId:0,
            songIds:[],
            playing: false,
            currentTime: 0,
            duration: 0,
            snackbar: false,
            text: '',
            title: '加载中',
            userName: '加载中',
            tags: '加载中',
            audios: '',
            imageUrl:'',
            lyric: '',
            isDragging: false, // 添加一个标志位来记录是否正在拖动滑块
            //音乐列表下标
            songIndex:0,
            genreMap: {
          "Male Vocal":"男声",
      "Female Vocal":"女声",
  "Hip Hop": "嘻哈",
  "R&B/Soul": "R&B",
  "Traditional/Chinese Style": "古风音乐",
  "Post-Rock": "后摇",
  "Classical Music": "古典音乐",
  "Jazz": "爵士乐",
  "Rock": "摇滚乐",
  "Pop": "流行音乐",
  "Hip Hop/Rap": "说唱音乐",
  "Electronic Dance Music (EDM)": "电子舞曲",
  "Country": "乡村音乐",
  "Folk": "民谣音乐",
  "Blues": "蓝调音乐",
  "Soul": "灵魂乐",
  "Heavy Metal": "重金属音乐",
  "Punk Rock": "朋克摇滚",
  "Funk": "放克音乐",
  "Reggae": "雷鬼音乐",
  "World Music": "世界音乐",
  "Alternative Rock": "另类摇滚",
  "Indie": "独立音乐",
  "Hard Rock": "硬摇滚",
  "New Age": "新世纪音乐",
  "Jazz Rock": "爵士摇滚",
  "Progressive Rock": "进步摇滚",
  "Experimental": "实验音乐",
  "Electronic": "电子音乐",
  "Industrial": "工业音乐",
  "Soft Rock": "抒情摇滚",
  "Pop Punk": "流行朋克",
  "Chicago Blues": "芝加哥蓝调",
  "Delta Blues": "德尔塔蓝调",
  "Nu Jazz/Electro Jazz": "电子爵士",
  "Ambient": "环境音乐",
  "Ethnic/World": "民族音乐",
  "Latin": "拉丁音乐",
  "K-POP": "K-POP",
  "J-POP": "J-POP",
  "Opera": "歌剧",
  "Chamber Music": "室内乐",
  "Drum and Bass": "D&B（鼓打贝斯）",
  "Cuban Music": "古巴音乐",
  "Bossa Nova": "博萨诺瓦",
  "Early Music": "古代音乐",
  "Electro Punk": "庞克电子",
  "Crunk": "嘻游",
  "Grammy Style (not a genre but associated with varying styles recognized at the Grammy Awards)": "格莱美风格",
  "Black Metal": "黑金属",
  "Death Metal": "死亡金属",
  "Folk Metal": "民谣金属",
  "Gothic Rock": "哥特摇滚",
  "Experimental Rock": "实验摇滚",
  "Digital Hardcore": "数码硬核",
  "Electroacoustic Folk": "电子民谣",
  "Post-Punk": "后朋克",
  "New Wave": "新浪潮",
  "Emo (Emotional Hardcore)": "情感硬核",
  "Ska": "斯卡音乐"
},
        };
    },
    created() {
        if(this.$route.query.id){
            this.songId = this.base64ToString(this.$route.query.id)
        }

        if(this.$route.query.ids){
            this.songIds = this.base64ToString(this.$route.query.ids).split(',')
        }
    },
    computed: {
        formattedCurrentTimeLabel() {
            // 将播放时间转换为分钟和秒数
            const minutes = Math.floor(this.currentTime / 60);
            const seconds = Math.floor(this.currentTime % 60);
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(seconds).padStart(2, '0');
            return `${formattedMinutes}:${formattedSeconds}`;
        },
        formattedDuration() {
            // 将总时长转换为分钟和秒数
            if (isNaN(this.duration)) {
                return '00:00';
            }
            const minutes = Math.floor(this.duration / 60);
            const seconds = Math.floor(this.duration % 60);
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(seconds).padStart(2, '0');
            return `${formattedMinutes}:${formattedSeconds}`;
        }
    },
    mounted() {
        window.scrollTo(0, 0);
        this.$axios.get('/music/getMusic?id='+this.songId).then(response => {
            if(response.data.data===null){
                this.snackbar = true;
                this.text = '抱歉，歌曲已被作者隐藏或删除';
                this.title = '歌曲已下架'
                return;
            }
               this.title = response.data.data.title
               this.userName = response.data.data.userName
               this.tags = response.data.data.tags
               this.imageUrl = response.data.data.imageUrl
               this.lyric = response.data.data.lyric
               this.duration = response.data.data.duration
               this.$globalAudio.addEventListener('timeupdate', this.updateProgress);
                this.$globalAudio.addEventListener('ended', this.onEnded);
                this.$globalAudio.src = response.data.data.audio;
                this.$globalAudio.load(); // 确保音频加载完成
                this.$globalObject.typeId = 0;
                if(this.$route.query.ids){
                this.playing = true;
                this.$globalAudio.play();
                this.addPlayNumber(response.data.data.nativeId);
                }
           }).catch(error => {
                this.snackbar = true;
                this.text = '获取歌曲信息失败，请检查网络连接或稍后再试';
                return;
            });
       
    },
    beforeDestroy() {
        if (this.$globalAudio) {
            this.$globalAudio.removeEventListener('timeupdate', this.updateProgress);
            this.$globalAudio.removeEventListener('loadedmetadata', () => { });
            this.$globalAudio = null;
        }
    },
    methods: {
        translateMusicGenreToChinese(str) {
            const parts = str.split(',');
      let newStr = '';
      for(let i = 0; i < parts.length; i++){
        if(this.$genreMapReversed[parts[i]]){
          newStr+=this.$genreMapReversed[parts[i]]+','
        }else{
          newStr+=parts[i]+','
        }
        
      }
      if (newStr.endsWith(',')) { 
        return newStr.slice(0, -1); // 返回去掉最后一个字符的字符串
      }
      return newStr;  },
        base64ToString(base64) {
    // 使用 atob 将 Base64 字符串转换为 ArrayBuffer
    const binaryString = atob(base64);
    // 使用 TextDecoder 将 ArrayBuffer 转换为原始字符串
    return new TextDecoder("utf-8").decode(new Uint8Array([...binaryString].map(c => c.charCodeAt(0))));
},
        togglePlay() {
            
            if (this.playing) {
                this.$globalAudio.pause();
            } else {
                this.$globalAudio.play();
            }
            this.playing = !this.playing;
        },
        sliderStart() {
            // 用户开始拖动滑块
            this.$globalAudio.pause();
            this.playing = false;
            this.isDragging = true;
        },
        sliderEnd() {
            // 用户结束拖动滑块
            this.$globalAudio.currentTime = this.currentTime;
            this.isDragging = false;
            this.$globalAudio.play();
            this.playing = true;
        },
        onEnded() {
            this.playing = false;
        },
        setProgress(newTime) {
            this.$globalAudio.currentTime = newTime;
            this.currentTime = newTime;
        },
        updateProgress() {
            this.currentTime = this.$globalAudio.currentTime;
        },
        prevTrack() {
            // 实现上一曲逻辑
            if(this.songIndex <= 0){
                this.snackbar = true;
                this.text = '已经是第一首了';
                return;
            }
            
            this.songIndex--;
            this.$axios.get('/music/getMusic?id='+this.songIds[this.songIndex]).then(response => {
                if(response.data.data===null){
                this.snackbar = true;
                this.text = '抱歉，歌曲已被作者隐藏或删除';
                this.title = '歌曲已下架'
                return;
            }
               this.title = response.data.data.title
               this.userName = response.data.data.userName
               this.tags = response.data.data.tags
               this.imageUrl = response.data.data.imageUrl
               this.lyric = response.data.data.lyric
               this.duration = response.data.data.duration
               this.$globalAudio.addEventListener('timeupdate', this.updateProgress);
                this.$globalAudio.addEventListener('ended', this.onEnded);
                this.$globalAudio.src = response.data.data.audio;
                this.$globalAudio.load(); // 确保音频加载完成
                this.$globalObject.typeId = 0;
                this.$globalAudio.play();
                this.playing = true;
                this.addPlayNumber(response.data.data.nativeId)
           }).catch(error => {
                this.snackbar = true;
                this.text = '获取歌曲信息失败，请检查网络连接或稍后再试';
                return;
            });
        },
        nextTrack() {
            if(this.songIndex >= this.songIds.length-1){
                this.snackbar = true;
                this.text = '已经是最后一首了';
                return;
            }
            this.songIndex++;
            this.$axios.get('/music/getMusic?id='+this.songIds[this.songIndex]).then(response => {
                if(response.data.data===null){
                this.snackbar = true;
                this.text = '抱歉，歌曲已被作者隐藏或删除';
                this.title = '歌曲已下架'
                return;
            }
               this.title = response.data.data.title
               this.userName = response.data.data.userName
               this.tags = response.data.data.tags
               this.imageUrl = response.data.data.imageUrl
               this.lyric = response.data.data.lyric
               this.duration = response.data.data.duration
               this.$globalAudio.addEventListener('timeupdate', this.updateProgress);
                this.$globalAudio.addEventListener('ended', this.onEnded);
                this.$globalAudio.src = response.data.data.audio;
                this.$globalAudio.load(); // 确保音频加载完成
                this.$globalObject.typeId = 0;
                this.$globalAudio.play();
                this.playing = true;
                this.addPlayNumber(response.data.data.nativeId);
           }).catch(error => {
                this.snackbar = true;
                this.text = '获取歌曲信息失败，请检查网络连接或稍后再试';
                return;
            });
        },
        kuaijin() {
            this.$globalAudio.currentTime += 10;
        },
        kuaitui() {
            this.$globalAudio.currentTime -= 10;
        },
        addPlayNumber(id){
            this.$axios.post('/music/addNumberOfViews',id).then(response => {
            })
        }
    }
}
</script>

<style scoped>
.headline {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: white;
}

.headline-two {
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
    color: white;
}
.custom-pre {
  word-break: break-all; /* 长单词断行 */
  white-space: pre-wrap; /* 保留换行符并允许文本换行 */
}
</style>