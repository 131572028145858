<template>
    <v-container fluid class="home-container">
      <v-row align="center" justify="center" :class="windowWidth>768?mt-10:mt-0">
        <v-col cols="12" md="8">
          <v-card elevation="0" class="text-left pa-6">
            <v-card-title class="display-2 font-weight-bold mb-4 d-flex align-center">
              <v-icon :size="windowWidth>768?72:32">mdi-phone</v-icon>
              <span class="ml-4">联系我们</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="text-justify">
              &emsp;如您需要帮助，请私信我们<b>（客服在线时间：周一至周五 9:00-18:00）</b>
              <v-img 
                :src="require('../assets/weixingzh.png')"
                class="mt-5"
                
              ></v-img>
            </v-card-text>
            <v-divider></v-divider>
            <v-row align="center" justify="center" class="mt-5">
              <p class="">&copy; 2024 sunosong.cn. 保留所有权利。</p>
            </v-row>
           
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'ContactUs',
    data() {
      return {
        windowWidth: window.innerWidth,
      };
    },
    methods: {
      updateWindowDimensions() {
        this.windowWidth = window.innerWidth;
      }
    },
    mounted() {
      window.addEventListener('resize', this.updateWindowDimensions);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }
  };
  </script>
  
  <style scoped>
  .home-container {
    background: linear-gradient(135deg, #e9dcb9, #085594);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    .home-container {
    background: linear-gradient(135deg, #e9dcb9, #085594);
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    }
  }
  
  .display-2, .headline {
    font-size: 72px !important;
    line-height: 1.2;
    color: #333;
    margin-bottom: 20px;
  }
  
  .text-justify {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
    text-align: justify;
  }
  
  .v-card {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
  }
  
  @media (max-width: 768px) {
    .display-2, .headline {
      font-size: 32px !important;
    }
  
    .text-justify {
      font-size: 16px;
      line-height: 1.4;
    }
  
    .v-icon {
      font-size: 32px !important;
    }
  }
  </style>