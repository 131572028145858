<template>
    <v-container fluid class="home-container">
      <v-row align="center" justify="center" :class="windowWidth > 768 ? 'mt-10' : 'mt-0'">
        <v-col cols="12" md="8">
          <v-card elevation="0" class="text-left pa-6">
            <v-card-title class="display-2 font-weight-bold mb-4 d-flex">
              <v-icon :size="windowWidth > 768 ? 48 : 18">mdi-music-note</v-icon>
              <span>Sunosong 的主要功能</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-5 text-justify">
              <ul>
                <li><strong>生成歌曲的文本描述：</strong>通过输入简单的歌曲说明和提示词，可以快速生成一首完整的歌曲，并支持自定义歌词。</li>
                <li><strong>丰富的音乐风格：</strong>支持多种音乐风格，包括流行、R&B、嘻哈、乡村、电子、迷幻等。</li>
                <li><strong>多语言支持：</strong>支持50多种语言的歌曲生成，包括英文、中文、法语、日语、俄语、西班牙语等。</li>
              </ul>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-title class="headline font-weight-bold mb-4 d-flex">
              <v-icon :size="windowWidth > 768 ? 48 : 18">mdi-play-circle-outline</v-icon>
              <span>如何使用 SunoSong AI 生成歌曲</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="text-justify">
              <ol>
                <li><strong>访问网站：</strong>打开 <a href="https://www.sunosong.cn" target="_blank">www.sunosong.cn</a>，点击左下角的【登录账号】进入用户界面。</li>
                <li><strong>输入创作信息：</strong>在创作页面中输入情感、风格和主题，AI将根据这些信息生成音乐。</li>
                <li><strong>生成音乐：</strong>填写完信息后，点击【创作音乐】，稍等片刻即可生成歌曲。</li>
                <li><strong>分享和编辑：</strong>生成的歌曲可以进行分享、预览和Remix。</li>
              </ol>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-title class="headline font-weight-bold mb-4 d-flex">
              <v-icon :size="windowWidth > 768 ? 48  : 18">mdi-frequently-asked-questions</v-icon>
              <span>常见问题</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="text-justify">
              <ol>
                <li><strong>谁拥有与我的歌曲一起创作的艺术品？</strong>
                  <p>如果你是付费订阅用户，那么你拥有订阅期间创作的歌曲及其相关艺术作品的所有权，前提是遵守 SunoSong 的服务条款。</p>
                  <p>如果你使用的是免费赠送的积分，我们保留你创作的歌曲及其相关艺术作品的所有权，但你可以将这些艺术作品用于非商业目的，并且必须遵守我们的服务条款。</p>
                  <p>请注意，由于机器学习的特性，输出内容可能不是每个用户独有的，特别是当输入相似时，生成的艺术作品可能会相似或相同。</p>
                </li>
                <li><strong>我可以将歌词上传到现有歌曲中吗？</strong>
                  <p>不可以，除非你是这些现有歌词的版权所有人，或者你已获得版权所有人的明确许可，允许你将现有歌词上传到 SunoSong。</p>
                </li>
                <li><strong>商业用途的例子有哪些？</strong>
                  <p>商业用途包括任何产生收入的活动。例如，在 YouTube、抖音等平台上进行货币化，将歌曲上传到 Spotify 和 Apple Music 等音乐流媒体服务，以及将歌曲用于广告、电影、电视节目或播客中的授权使用。</p>
                </li>
                <li><strong>谁拥有我使用创作的歌曲？</strong>
                  <p>如果你是我们的付费用户，生成的歌曲归你所有，但需遵守我们的服务条款。</p>
                  <p>如果你使用赠送积分生成的歌曲，我们将保留这些歌曲的所有权，但你可以将这些歌曲用于非商业目的，前提是你遵守服务条款。</p>
                </li>
                <li><strong>如果我取消订阅，我是否会失去将我作为订阅用户创建的歌曲用于商业用途的权利？</strong>
                  <p>如果你取消订阅，你将继续享有永久使用在你订阅期间生成的内容的权利，包括用于商业用途。</p>
                </li>
                <li><strong>如果我自己写歌词，提交后我还拥有它们吗？</strong>
                  <p>是的，无论你使用哪种版本的 SunoSong，你都保留对你创建并输入到 SunoSong 的任何原创内容的所有权和权利。</p>
                </li>
                <li><strong>我使用 SunoSong 创作的歌曲是否受到版权保护？</strong>
                  <p>使用人工智能生成的内容的版权保护是一个复杂且不断变化的法律领域，各国的规定各不相同。我们建议你咨询合格的律师，了解你使用生成的内容的最新版权保护情况。</p>
                </li>
                <li><strong>当我在社交媒体（如抖音、头条、视频号、快手等）上发布歌曲或艺术品时，是否需要归因于 SunoSong？</strong>
                  <p>免费用户需要署名（例如“Made With SunoSong”），付费用户则不需要。</p>
                </li>
                <li><strong>订阅其中一个付费订阅计划是否会授予我作为免费用户生成的歌曲的追溯商业权利？</strong>
                  <p>不会，订阅付费计划并不会授予你作为免费用户生成的内容的追溯性商业权利。免费用户生成的内容只能用于非商业目的。</p>
                </li>
                <li><strong>我可以自己重新录制一首歌吗？</strong>
                  <p>无论你使用哪种版本的 SunoSong，你都保留对你创建并输入到 SunoSong 的任何原创内容的所有权和权利，包括重新录制音乐内容的权利，前提是遵守 SunoSong 的服务条款。</p>
                  <p>对于使用 SunoSong 生成的新音乐或歌词，你也可以重新录制音乐内容。但如果音乐内容是在你使用免费版本的 SunoSong 时生成的，那么重新录制的歌曲只能用于非商业目的，并且必须附带对 SunoSong 的归属。</p>
                </li>
                <li><strong>我可以在 YouTube 上赚钱吗？</strong>
                  <p>如果你是免费用户，你只能将歌曲用于非商业用途，因此不允许在 YouTube 上进行货币化。如果你想将歌曲用于商业用途，你需要订阅我们的付费计划之一。</p>
                </li>
              </ol>
            </v-card-text>
            <v-divider></v-divider>
            <v-row align="center" justify="center" class="mt-5">
              <p class="">&copy; 2024 sunosong.cn. 保留所有权利。</p>
            </v-row>
            
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'FrequentlyAskedQuestions',
    data() {
      return {
        windowWidth: window.innerWidth,
      };
    },
    methods: {
      updateWindowDimensions() {
        this.windowWidth = window.innerWidth;
      }
    },
    mounted() {
      window.addEventListener('resize', this.updateWindowDimensions);
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }
  };
  </script>

<style scoped>
.home-container {
  background: linear-gradient(135deg, #e9dcb9, #085594);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .home-container {
    background: linear-gradient(135deg, #e9dcb9, #085594);
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.display-2, .headline {
  font-size: 32px !important;
  line-height: 1.2;
  color: #333;
  margin-bottom: 20px;
}

.text-justify {
  font-size: 18px;
  line-height: 1.6;
  color: #555;
  text-align: justify;
}

.v-card {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
}

.v-icon {
  color: #085594;
}

@media (max-width: 768px) {
  .display-2, .headline {
    font-size: 18px !important;
  }

  .text-justify {
    font-size: 16px;
    line-height: 1.4;
  }

  .v-icon {
    font-size: 32px !important;
  }
}
</style>